import React from "react";
import "../stylesheet/InnovationR&D.css";

function InnovationRD() {
  return (
    <div className="innovation-r-d">
      <div className="innovation-r-d-content">
        <div className="innovation-r-d-content-left">
          <p className="p text-black justify-text">
            R&D, an essential function for many businesses, is responsible for
            achieving breakthroughs in innovation.
          </p>
          <p className="p text-black justify-text">
            R&D always goes along with product development and behind every
            innovative product invention are decades of extensive research that
            ignites a spark within the organization.
          </p>
          <p className="p text-black justify-text">
            Research is the most endangered investment area because both the
            development of inventiveness and its prosperous understanding takes
            doubt, including the profitability of the creativeness. In a
            resolution, "huge R and D spending does not assure a more creative,
            more fabulous profit or more market share".
          </p>
        </div>
        <div className="innovation-r-d-content-right">
          <img src="/images/innovations-r-and-d-small.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export default InnovationRD;

//image is missing
