
import React from "react";
import BlogTwentyContent from "./components/BlogTwentyContent";
import TopFixedHeader from '../../../../common/components/TopFixedHeader';
import BlogPageHome from '../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogTwentyThree() {
  return (
    <>
      <Helmet>
        <title>How to Create a Killer SEO Strategy for Your Website | Lejhro
        </title>
        <meta name="description" content="Improve your website's search engine ranking with our killer SEO strategy guide. Learn proven techniques and boost your online visibility. " />
        <meta name="keywords" content="SEO, Website, Killer SEO tactics, Ranking, Search Engine Optimization"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogTwentyContent/>
    </>
  );
}

export default BlogTwentyThree;



        // {
        //     "title" : "Create a Killer SEO Strategy for Your Website",
        //     "content":"Learn to build a machine learning model using Apache Spark. Follow our step-by-step guide and leverage the power of distributed computing.",
        //     
        
          //"link":"/blogs/how-to-create-killer-seo-strategy-for-your-website"
        // },