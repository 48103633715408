import React from "react";
import "../Stylesheet/DrivingInnovationThroughChange.css";

function DrivingInnovationThroughChange() {
  
  return (
      <div className="DrivingInnovationThroughChange-div" id="page1">
        <div className="DrivingInnovationThroughChange-content">
          <div className="DrivingInnovationThroughChange-inside-div">
            <h1 className="homepage-heading">
              Driving Innovation Through Change
            </h1>
            <h2 className="h4-subheading">
              Explore, learn and adapt to change for a sustainable future
            </h2>
          </div>
          <div
            className="DrivingInnovationThroughChange-button page1-button"
            onClick={() => {
              window.location.pathname = "/innovations";
            }}
          >
            Read More
          </div>
        </div>
        <div className="DrivingInnovationThroughChange-img">
          <img src="images/lejhro-innovation.png" alt="" />
        </div>
        <div className="DrivingInnovationThroughChange-img-mobile">
          <img src="images/lejhro-home-page1-mobile.png" alt="" />
        </div>
      </div>
  );
}

export default DrivingInnovationThroughChange;
