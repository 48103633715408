import React from "react";
import BlogThirteenContent from "./components/BlogThirteenContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogThirteen() {
  return (
    <>
      <Helmet>
        <title>Data Analytics Tools | Lejhro</title>
        <meta name="description" content="If you are intrigued by data analytics you can also upskill yourself by delving deep into the ideas you need to know from Tableau to Excel and many more." />
        <meta name="keywords" content="Tableau, Microsoft Excel, R, Python, SQL"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogThirteenContent/>
    </>
  );
}

export default BlogThirteen;
