import React from "react";
import "../../../stylesheets/BlogContent.css";
import Links from "../../../../../data/Links.json";

const BlogTenContent = () => {
  const { LejhroBootcampURL } = Links;
  return (
    <div className="blog-content">
      <div className="blog-content-container">
        <h1 className="h2 text-black">
          Advanced Python Concepts that You Must Know
        </h1>
        <p className="p">
          In today's world where technology is changing rapidly, keeping oneself
          updated with advanced technology is a smart way to be ahead in your
          career. In this blog you will get to know the advanced Python concepts
          that you must know, How to learn these new concepts. Don't be
          overwhelmed, Lejhro has come up with the bootcamp to help you learn
          effectively.{" "}
          <a href={LejhroBootcampURL} target="_blank" rel="noreferrer">Lejhro Bootcamp</a>
        </p>
        <p className="p-bold">Map Function</p>
        <p className="p">
          Map() which lets us process the elements which are present in an
          iterable without explicitly using a looping construct. When used, it
          returns a map object which in turn is an iterator. This map object is
          the result obtained by applying the specified function to every item
          present in the iterable.
        </p>
        <p className="p">
          Function definition – required_answer = map(function, iterable)
        </p>

        <p className="p-bold">Itertools</p>
        <p className="p">
          Python has an amazing standard library called itertools which provides
          a number of functions that help in writing clean, fast, and
          memory-efficient code due to lazy evaluation. Some examples of
          functions present in itertools are: count(), cycle(), repeat(),
          accumulate(), product(), permutations(), combinations() etc. each
          taking their own set of arguments and operating upon them.
        </p>

        <p className="p-bold">Lambda Function</p>
        <p className="p">
          Python’s lambda functions are small anonymous functions as they do not
          have a name and are contained in a single line of code. The keyword
          ‘def’ is used to define functions in Python but lambda functions are
          rather defined by the keyword ‘lambda’.
        </p>
        <p className="p">
          Function definition – required_answer = lambda ..arguments :
          expression
        </p>

        <p className="p-bold">Exception Handling</p>
        <p className="p">
          Exceptions are types of errors that occur when the program is being
          executed and change the normal flow of the program. An example could
          be dividing a number by zero or referencing an index that is outside
          the bounds of an iterable. Therefore, we use try, except, and finally
          to handle exceptions in Python. The keyword try is used to wrap a
          block of code that can potentially throw errors, except is used to
          wrap a block of code to be executed when an exception is raised and
          handles the error, and finally lets us execute the code no matter
          what.
        </p>

        <p className="p-bold">Decorators</p>
        <p className="p">
          Decorators are a part of Python’s metaprogramming which are used to
          add additional functionality to existing code without altering the
          original structure at compile time. It is more like a regular function
          in Python that can be called and returns a callable. It takes in a
          function, modifies it by adding functionality, and then returns it.
        </p>
        <p className="p">
          Want to start off in the field of Data Analytics & become a master in
          it? So get ready and learn the various aspects starting from the
          basics of Python with Lejhro’s bootcamp.
        </p>

        <p className="p">
          You will have easy learning of the complex concepts. Our experts
          understand your needs and value your time, the curriculum is made in
          the most practical way to suit you.
        </p>
      </div>
    </div>
  );
};

export default BlogTenContent;
/*
<p className='p-bold'></p>
      <p className='p'></p>
*/
