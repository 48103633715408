import React from "react";
import BlogNineteenContent from "./components/BlogNineteenContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogNineteen() {
  return (
    <>
      <Helmet>
        <title>Key Data Science Trends For 2022-2025 | Lejhro</title>
        <meta name="description" content="With Data Science taking the forefront, what will be its recent future is what everyone is inquisitive about. Extensive research on data science is in here." />
        <meta name="keywords" content="Deepflake,python,data science,data analyst, machine learning, artificial intelligence, Data Science trends"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogNineteenContent/>
    </>
  );
}

export default BlogNineteen;
