import React from "react";
import "../../../stylesheets/BlogContent.css";

const BlogSeventeenContent = () => {
  return (
    <div className="blog-content">
      <div className="blog-content-container">
        <h1 className="h2 text-black">Explore the World of Data</h1>
        <p className="p-bold">
          Ben Schneiderman: ” Visualisation gives you answers to questions you
          didn’t know you had.”
        </p>
        <p className="p">
          Representation of raw data into a pictorial or graphical form is
          essentially Data Visualisation. Through Data visualisation,
          decision-makers can understand, grasp as well as draw conclusions from
          it, thereby identifying new patterns and implementing them in the best
          manner possible i.e, to expand their businesses. With new
          technologies, data can be presented in charts and graphs for more
          accurate information.
        </p>
        <p className="p-bold">Why does a company need to visualise?</p>
        <p className="p">
          It is a scientifically proven fact that the human brain can comprehend
          and understand data more easily when it can visualise it in the form
          of charts, graphs etc hence data visualisation saves time and is an
          efficient and immaculate technique to deliver useful information.
        </p>

        <p className="p">
          Apart from this, data visualisation is also able to identify the
          crucial areas which require attention and improvement. It helps
          understand the factors that influence customer behaviour hence useful
          in predicting the sales volume.
        </p>
        <p className="p-bold">How Data Visualisation is being Employed?</p>

        <ul className="p">
          <li>
            <p className="p">
              <b>To comprehend information quickly-</b>
            </p>
            <p className="p">
              Massive data is now being converted and presented in a precise,
              easy, time-saving method by depicting it in the form of charts and
              graphs in contrast to depicting data in lengthy spreadsheets.
            </p>
          </li>
          <li>
            <p className="p">
              <b>Identify and determine relationships and patterns-</b>
            </p>
            <p className="p">
              Huge chunks of information also start making sense once they are
              presented in a proper graphical manner. It helps identify the
              important relationships thereby enabling organisations to focus on
              more crucial areas which are likely to affect their goals.
            </p>
          </li>
          <li>
            <p className="p">
              <b>Recognize emerging new trends-</b>
            </p>
            <p className="p">
              Data visualisation enables users to identify trends both in the
              business as well as the market hence giving one an edge over the
              others. It also makes it easy for one to identify outliers that is
              likely to affect product quality. Hence, the problem can be fixed
              before it gets bigger.
            </p>
          </li>
          <li>
            <p className="p">
              <b>Communicate the story</b>
            </p>
            <p className="p">
              Once new insights from visual analytics have been unravelled, the
              next step is to communicate them effectively by using charts,
              graphs and other visually impactful representations as it engages
              and gets the message across efficiently.
            </p>
          </li>
        </ul>
        <p className="p">
          We at Lejhro make you learn what corporate experts accept, so enrol
          yourself and learn the art of data visualisation.
        </p>
      </div>
    </div>
  );
};

export default BlogSeventeenContent;
/*
<p className='p-bold'></p>
      <p className='p'></p>
*/
