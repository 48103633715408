import React from "react";
import "../Stylesheet/AccelerateYourBusiness.css";

function AccelerateYourBusiness() {
  return (
      <div className="AccelerateYourBusiness-div" id="page3">
        <div className="AccelerateYourBusiness-content">
          <div className="AccelerateYourBusiness-inside-div">
            <h2 className="homepage-heading">Accelerate Your Business</h2>
            <h3 className="h4-subheading">
              Adapt to digital transformation to meet the evolving market
            </h3>
          </div>
          <div
            className="AccelerateYourBusiness-button"
            onClick={() => {
              window.location.pathname = "/business-services";
            }}
          >
            Explore
          </div>
        </div>
        <div className="AccelerateYourBusiness-img">
          <img src={`images/lejhro-home-page3.png`} alt="" />
        </div>
        <div className="AccelerateYourBusiness-img-mobile">
          <img src={`images/lejhro-home-page3-mobile.png`} alt="" />
        </div>
      </div>
  );
}

export default AccelerateYourBusiness;
