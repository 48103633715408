import React from "react";
import { Helmet } from 'react-helmet-async';
import "./Homepage.css"
import DrivingInnovationThroughChange from "./Components/DrivingInnovationThroughChange"
import EmpoweringTheZoomers from "./Components/EmpoweringTheZoomers"
import AccelerateYourBusiness from "./Components/AccelerateYourBusiness"
import BuildYourFutureWorkspace from "./Components/BuildYourFutureWorkspace"
import OurPurpose from "./Components/OurPurpose"
import LetsTalk from "./Components/LetsTalk"
import SideNavigationBar from './Components/SideNavigationBar';
import TopFloatingHeader from './Components/TopFloatingHeader';

function Homepage() {
  return (
    <div>
      <Helmet>
        <title>Lejhro | Driving Innovation Through Change</title>
        <meta name="description" content="Our aim is to empower people to innovate exceptional solutions in bringing a revolution in the industry with our rebellious spirit and out-of-the-box thinking." />
        <meta name="keywords" content="Lejhro, Innovation, Change, Services, Consulting, Bootcamp, Technology, Training"/>
      </Helmet>
      <SideNavigationBar/>
      <TopFloatingHeader/>
      <div id="homepage-upper-divs">
      <DrivingInnovationThroughChange/>
      <EmpoweringTheZoomers/>
      <AccelerateYourBusiness/>
      <BuildYourFutureWorkspace/>
      </div>
      <OurPurpose/>
      <LetsTalk/>
    </div>
  );
}

export default Homepage;
