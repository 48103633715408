import React from "react";
import BlogTwoContent from "./components/BlogTwoContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogTwo() {
  return (
    <>
      <Helmet>
        <title>5 Things That Tell You To Pursue a Different Career | Lejhro</title>
        <meta name="description" content="Opportunities expands your connections and your ways to explore. Here are the best 5 options that boost and motivate to choose your career motives in future." />
        <meta name="keywords" content="Guidance and successful career development, statistics of market analysis, future of particular field,highly paid, learn innovative tactics throughout"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogTwoContent/>
    </>
  );
}

export default BlogTwo;
