import React from "react";
import BlogSeventeenContent from "./components/BlogSeventeenContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogSeventeen() {
  return (
    <>
      <Helmet>
        <title>Explore the World of Data | Lejhro</title>
        <meta name="description" content="Making progress on data would be incredibly beneficial to businesses. Understand the innovations and trends in data to improve the scope of your business." />
        <meta name="keywords" content="Data Visualisation, Data storytelling, Data for Business"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogSeventeenContent/>
    </>
  );
}

export default BlogSeventeen;
