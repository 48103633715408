import React, { useState } from "react";
import "../stylesheets/TopHeader.css";
import SideDrawer from "./SideDrawer";
import { Button } from "antd";
import Links from "../../data/Links.json";

const TopHeader = () => {
  const { LejhroBootcampURL } = Links;

  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <div className="top-header-header-div">
      <SideDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <div className="top-header-header-logo-div">
        <a href="/" aria-label="HomePage">
          <img
            src="/lejhro_logo_white.png"
            alt="Lejhro White Logo"
            className="top-header-fixed-logo"
          />
        </a>
      </div>
      <div className="top-header-links-div">
        <a href="/innovations">Innovations</a>
        <a href="/business-services">Business Services</a>
        <a href="/financial-services">Financial Services</a>
        <a href={LejhroBootcampURL} target="_blank" rel="noreferrer">
          Bootcamp
        </a>
        <Button
          onClick={() => {
            setDrawerOpen(true);
          }}
          className="top-header-drawer-button-links"
          aria-label="Open Drawer"
        >
          <div className="side-drawer-button-container-div-line1" />
          <div className="side-drawer-button-container-div-line2" />
          <div className="side-drawer-button-container-div-line1" />
        </Button>
      </div>
      <Button
        onClick={() => {
          setDrawerOpen(true);
        }}
        className="top-header-drawer-button"
        aria-label="Open Drawer"
      >
        <div className="side-drawer-button-container-div-line1" />
        <div className="side-drawer-button-container-div-line2" />
        <div className="side-drawer-button-container-div-line1" />
      </Button>
    </div>
  );
};

export default TopHeader;
