
import React from "react";
import BlogTwentyContent from "./components/BlogTwentyContent";
import TopFixedHeader from '../../../../common/components/TopFixedHeader';
import BlogPageHome from '../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogTwentyEight() {
  return (
    <>
      <Helmet>
        <title>How to Land Your First Job in Python: Tips & Guidance | Lehro
        </title>
        <meta name="description" content="Build a strong portfolio and gain exposure to trending tech with networking. Learn how to showcase your skills to potential employers.

" />
        <meta name="keywords" content="Portfolio, Python Framework, Fundamental, Skills required, grow network, Relevant Projects
"/>
      </Helmet>
      <TopFixedHeader />
      <BlogPageHome />
      <BlogTwentyContent />
    </>
  );
}

export default BlogTwentyEight;



// {
//     "title" : "Land your career in Python: Tips & Guidance",
//     "content":"Build a strong portfolio and gain exposure to trending tech with networking. Learn how to showcase your skills to potential employers."


        //   "link":"/blogs/land-your-career-in-python-tips-guidance"
        // },