import React from "react";
import "../stylesheet/InnovationResearch.css";

function InnovationResearch() {
  return (
    <div className="innovation-research">
      <h2 className="h2 white-heading">Research Development</h2>
      <p className="p white-heading">
        We at Lejhro believe Research & Development(R&D) as the best investment
        for the future.
      </p>
      <div className="innovation-research-img">
        <img
          src="/images/innovations-research-1440x593.png"
          alt=""
        />
      </div>
    </div>
  );
}

export default InnovationResearch;

//image is missing
