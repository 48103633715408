import React from "react";
import "../../../stylesheets/BlogContent.css";

const BlogEightContent = () => {
  return (
    <div className="blog-content">
      <div className="blog-content-container">
        <h1 className="h2 text-black">Blockchain</h1>
        <p className="p">
          Friends, today we will discuss blockchain, what blockchain is and how
          it works. You must have known about cryptocurrency. It has a basic
          unit in cryptocurrency, bitcoin and now many coins have come in the
          market, which is directly related to cryptocurrency.
        </p>
        <p className="p">
          Cryptocurrency works inside blockchain, blockchain is the complete
          system under which Cryptocurrency works and Bitcoin works inside
          Cryptocurrency.
        </p>
        <p className="p">
          Blockchain works to determine all this and we will tell you how it
          will affect our daily life in the coming years, then we will first
          tell you what blockchain is and how it works.
        </p>
        <p className="p-bold">What is blockchain?</p>
        <p className="p">
          As its name suggests blockchain means it is a chain of blocks, it is
          called a group or group of blocks and all the blocks are connected to
          each other through cryptography. Each block holds a hash that serves
          to link the block with data and time details.
        </p>
        <p className="p">
          This serves to control a growing database of increasingly large
          databases. Japan's Satoshi Nakamoto invented the blockchain in 2008.
          This chain can be used as bitcoin. Bitcoin is a unit of blockchain, it
          is called cryptocurrency, as it is the currency of a country, in the
          same way, cryptocurrency is a private currency that works under the
          blockchain.
        </p>
        <p className="p-bold">How does blockchain work?</p>
        <ul className="p">
          <li>
            Blockchain means to set up a chain from block to block. One block is
            related to another block with lot of data
          </li>
          <li>
            The group of these small blocks is called blockchain, by which a
            very large chain is formed which is created by cryptography and
            allows it to be encrypted
          </li>
          <li>
            Each block has a unique structure consisting of its metadata and all
            its transaction details which are stored for further exchange
          </li>
          <li>
            Its metadata is stored in blocks and each header carries a hash
            function that indicates the formation of the chain along with what
            is on every other block. The mainline that makes up the chain is
            called the main blockchain.
          </li>
          <li>
            In blockchain the heading function of the block is the function of
            selecting the block by which a blockchain is formed
          </li>
        </ul>
        <p className="p-bold">How secure is blockchain?</p>
        <p className="p">
          This is a type of technology which is absolutely coins and this is an
          unhackable technology. To do any translation in this technology, all
          the nodes of the network have to be aggregated. Only then does the
          transaction happen? This task cannot be done for a single entity. If
          someone wants to do so he has to hack only one system or not he has to
          hack the whole network so hacking is not that easy.
        </p>

        <p className="p-bold">
          What are the technologies behind blockchain technology?
        </p>
        <p className="p">
          Some technology is involved behind this technology, which is something
          like this
        </p>

        <ul className="p">
          <li>Privacy key cryptography</li>
          <li>p2p network</li>
          <li>Program</li>
        </ul>

        <p className="p-bold">In what areas can blockchain be used?</p>
        <ul className="p">
          <li>
            Blockchain can also be used in information technology and data
            management which will be completely secure
          </li>
          <li>
            With blockchain technology, you can save your important legal
            documents
          </li>
          <li>
            This technology can also be used in the field of Internet Banking
            and Insurance
          </li>
          <li>This technology can be used to prevent cybercrime</li>
          <li>Cloud storage can also be done through blockchain technology</li>
          <li>E-governance can also be kept safe through this technology</li>
          <li>
            You can also include your smart contract in it which will be a
            secure methodology
          </li>
          <li>
            Many governments can also conduct e-voting through blockchain
            technology, which can be a secure, opaque method
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BlogEightContent;
/*
<p className='p-bold'></p>
      <p className='p'></p>
*/
