import React from "react";
import "../stylesheets/ContactHome.css";
import TopHeader from "./../../../common/components/TopHeader";
import TopFixedHeader from "./../../../common/components/TopFixedHeader";

function ContactHome() {
  return (
    <div className="contact-home">
      <TopHeader />
      <TopFixedHeader />
      <h1 className="contact-name h2-heading">Contact Us</h1>
    </div>
  );
}

export default ContactHome;
