import React from "react";
import BlogSixteenContent from "./components/BlogSixteenContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogSixteen() {
  return (
    <>
      <Helmet>
        <title>Data Visualization: Tools and Techniques | Lejhro</title>
        <meta name="description" content="To get ease in understanding data concepts, data visualization is necessary. Read the blog to learn more about the techniques used in Data Visualization." />
        <meta name="keywords" content="Google charts,Tableau,Grafana,Chartist, Fusion charts"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogSixteenContent/>
    </>
  );
}

export default BlogSixteen;
