import React from "react";
import BlogThirtySixContent from "./components/BlogThirtySixContent";
import TopFixedHeader from '../../../../common/components/TopFixedHeader';
import BlogPageHome from '../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogThirtySix() {
  return (
    <>
      <Helmet>
        <title>  7 Proven Strategies for Digital Marketing Success | Lejhro
        </title>
        <meta name="description" content="Unlock digital marketing success with 7 proven strategies.Elevate online presence and reach business goals through content and social media tactics.


" />
        <meta name="keywords" content="Digital Marketing, Social Media Platforms, Influencer Marketing, Content Strategy, Audience Engagement,  Target Audience, Social Media Marketing, Email Marketing, SEO, Website Traffic, Conversion rate .
"/>
      </Helmet>
      <TopFixedHeader />
      <BlogPageHome />
      <BlogThirtySixContent />
    </>
  );
}

export default BlogThirtySix;



// {
//     "title" : "Social Media: Boost Biz Growth with Engaging Content.",
//     "content":"Digital Marketing, Social Media, Social Media, Content Strategy, Using Hashtags, Audience Engagement,  Target Audience, Social Media Platforms, Social Media Marketing.",
//     "link":"/blogs/social-media-boost-biz-growth-with-engaging-content"
// },