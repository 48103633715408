import React from "react";
import "../../../stylesheets/BlogContent.css";

const BlogNineContent = () => {
  return (
    <div className="blog-content">
      <div className="blog-content-container">
        <h1 className="h2 text-black">Internet of Things (IoT) </h1>
        <p className="p">
          Computers and the Internet are such things that everyone must have
          heard about and also use in their daily life, but have you ever heard
          of this IOT or Internet of things? Have you heard about this, so
          advanced technology that we are almost using, but do you know what the
          Internet of things (IoT) is after all?
        </p>
        <p className="p">
          Let me tell you through an example, the Internet of things can
          automate all our work like what if with the help of the internet of
          things, your freeze automatically checks vegetables whether the
          vegetable is finished or not. Order online, in which you will not have
          to go to the market and the vegetables will come home, in which both
          your time and money will be saved.
        </p>

        <p className="p">
          In this post, I am going to tell you about what the Internet of things
          is and how it is going to make our life more comfortable. The Internet
          of things is such an example of technology, with the help of which we
          can do all our work in automatic mode, we will know how to use it.
        </p>
        <p className="p-bold">What is the Internet of Things?</p>
        <p className="p">
          The full form of IoT is the Internet of things. IoT is such a
          technology in which we can control and automate all things through the
          Internet by connecting all the physical things we have with the
          Internet.
        </p>
        <p className="p">
          In this way, we can control all the physical and daily use of things
          from any corner of the world and it has become impossible, so only
          because of the internet why not use the internet properly. And in
          simple language, it means like we have a bulb installed in our house
          and if we want to turn it on or off then we have to go to the board
          and turn the button on and off but if we connect it through the
          internet of things. So we can turn it on and off from our smartphone
          or computer sitting far away.
        </p>
        <p className="p">
          In this, we can think of all the things we use in daily life like all
          the bulbs in the house, washing machine, fridge, TV, oven, headphones,
          etc.
        </p>
        <p className="p-bold">Examples of Internet of Things (IoT)</p>
        <p className="p">
          {" "}<b>Smart Lock</b> By connecting the door lock of your house with
          the help of IoT devices, making it a part of IoT, you can open or
          close it without using a key, by connecting it to your phone,
          smartwatches etc.
        </p>
        <p className="p">
          {" "}<b>Home Automation</b> Imagine, if you are in your bed and
          someone tells you to wake up and turn on the lights, then you become
          very lazy to go to the board, but if IoT helps you do it without the
          board, how will you feel?
        </p>
        <p className="p-bold">
          Why should we use the Internet of Things (IoT)?
        </p>

        <ul className="p">
          <li>
            It provides us with Real-Time Monitoring, in which it provides the
            best possible resource allocation for its data
          </li>
          <li>
            Provides Best Possible Feedback for your Mental and Physical Health
            in the field of Medical also
          </li>
          <li>
            Provides as many possible Decision-making patterns for mobile phones
            as well
          </li>
          <li>
            In this, for those who have Global Potential, it provides Best
            Possible Connection to Local Providers
          </li>
        </ul>
        <p className="p-bold">Features of the Internet Of Things (IoT)</p>
        <p className="p">
          {" "}<b>Connectivity</b> Connectivity means what kind of connection is
          being established between all the devices used in IoT which means
          whether the connection between these IoT devices is secure, reliable,
          or not and communication requires high-speed data communication
        </p>
        <p className="p">
          {" "}<b>Artificial Intelligence</b> This means that this technology is
          made by combining hardware, calculations, and software together to
          make intelligence or smart. Due to which their capacity, intelligence
          increase, and it is easy for them to give smart output
        </p>
        <p className="p">
          {" "}<b>Sensing</b> If we talk about IoT, then the first idea comes to
          the sensor itself. The sensor creates such an active network around
          you, from which the sensor generates a data report according to the
          condition of that environment
        </p>
        <p className="p">
          {" "}<b>Security</b> In today's time, if we talk about the internet,
          then security is the first issue. In which IOT equipment is inherently
          insecure because in this we use the internet to send data from one
          medium to another, for this we can increase its security by doing good
          software and good quality products, in which we can increase its
          security in future. More advances will be seen
        </p>
        <p className="p">
          {" "}<b>Analyzing</b> After connecting the IoT devices, whatever data
          or information is collected in it, the data is analyzed by analyzing
          it in reality and the related information is separated
        </p>
      </div>
    </div>
  );
};

export default BlogNineContent;
/*
<p className='p-bold'></p>
      <p className='p'></p>
*/
