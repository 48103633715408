import React from "react";
import BlogThreeContent from "./components/BlogThreeContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogThree() {
  return (
    <>
      <Helmet>
        <title>Things to Not Put on Your CV | Lejhro</title>
        <meta name="description" content="In order to optimize your CV, here are some of the tips about what not to put on your CV which may help you go a long way in your career and professional life." />
        <meta name="keywords" content="CV, Resume, Things to not put, Resume tips, CV pointers"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogThreeContent/>
    </>
  );
}

export default BlogThree;
