import React from "react";
import "../stylesheets/AboutMission.css";

function AboutMission() {
  return (
    <div className="about-mission">
      <div className="about-mission-left">
        <img src="/images/about-vision.jpg" alt="" />
        <h4 className="h2 text-black">Vision</h4>
        <p className="p text-black justify-text">
          Lejhro was founded with a rebellious spirit and its objective was 'to
          be the change' and make a mark with its technological innovations and
          remarkable products.
        </p>
      </div>
      <div className="about-mission-right">
        <img src="/images/about-mission.jpg" alt="" />
        <h4 className="h2 text-black">Mission</h4>
        <p className="p text-black justify-text">
          Provide quality and cost effective training to inspire, innovate and
          adapt to the evolving technology and accelerate business growth.
        </p>
      </div>
    </div>
  );
}

export default AboutMission;
