import React from "react";
import "../stylesheets/BlogCard.css";
const BlogCard = (props) => {
  return (
    <div class="blog-card">
      <p className="h4">{props.title}</p>
      <p className="p">{props.content}</p>
      <p className="p">
        <a href={props.link}>Read More...</a>
      </p>
    </div>
  );
};

export default BlogCard;
