import React from "react";
import "../../../stylesheets/BlogContent.css";

const BlogSevenContent = () => {
  return (
    <div className="blog-content">
      <div className="blog-content-container">
        <h1 className="h2 text-black">Artificial Intelligence</h1>
        <p className="p">
          Artificial Intelligence is a fundamental element which itself deals
          with the simulation of human intelligence in computational devices and
          systems. In this blog, we are going to read about Artificial
          Intelligence. So without wasting your precious time let’s start the
          article
        </p>
        <p className="p-bold">What is Artificial Intelligence (AI)?</p>
        <p className="p">
          Artificial intelligence (AI) is an important branch of computer
          science, which deals with the creation of smart machines, where human
          intelligence is usually required. Artificial intelligence means
          intellectual ability created by man.
        </p>
        <p className="p">
          The ultimate goal of Artificial intelligence is to create devices that
          can act intelligently and independently and reduce human labor and
          manual work.
        </p>
        <p className="p">
          Amazon’s Alexa, Iphone’s Siri, Tesla cars, and digital applications
          such as Netflix and Amazon are super examples of Artificial
          intelligence technologies.
        </p>
        <p className="p-bold">Types of Artificial Intelligence</p>
        <p className="p">
          There are four types of Artificial Intelligence based on its
          functionality
        </p>

        <ul className="p">
          <li>Reactive Machines</li>
          <li>Limited Memory</li>
          <li>Theory of Mind</li>
          <li>Self-conscious</li>
        </ul>
        <p className="p-bold">Benefits of Artificial Intelligence</p>
        <p className="p">Use of AI in Science and Medicine</p>
        <ul className="p">
          <li>AI robots can recognize any disease better than humans.</li>
          <li>AI can solve many complex equations of maths for us.</li>
          <li>
            AI can answer complex questions for us, such as how the universe
            formed, why we exist, help find life on other planets, what was
            there before the Big Bang, and what's inside a black hole, among
            many others
          </li>
        </ul>
        <p className="p">Use of AI in Business</p>
        <ul className="p">
          <li>
            AI can work continuously for 24 hours without stopping, this will
            reduce the cost of making anything
          </li>
          <li>
            We can make such robots in the future which will increase our
            manufacturing capacity manifold. With the help of these, companies
            can provide very good services to their customers
          </li>
        </ul>
        <p className="p">Use of AI in the Health Sector</p>
        <ul className="p">
          <li>
            We will be able to make such computer programs that humans can talk
            to patients like experts and can also treat them. Patients will not
            have any hesitation in revealing their secret disease computer
            program
          </li>
          <li>
            In the future, if AI robots become more advanced, they can treat
            humans better than humans without any defects
          </li>
        </ul>
        <p className="p">Use of AI in Agriculture</p>
        <ul className="p">
          <li>
            With the help of AI, we can easily detect diseases and insects in
            the crop
          </li>
          <li>With AI, we can spray crops with absolute accuracy</li>
          <li>We can also estimate the yield of the crop</li>
        </ul>
        <p className="p">
          In the end, I would like to say this, in the coming time, life will
          become difficult for those people who are not educated. In the coming
          time, only those people who are highly skilled will be able to live
          their lives comfortably. That is why it is necessary to improve the
          education system further so that every person can mold himself
          according to the time.
        </p>

        <p className="p">
          I hope you know what Artificial Intelligence is. What are its
          advantages and disadvantages? Got it. If you have any questions in
          your mind then feel free to contact us anytime.
        </p>
      </div>
    </div>
  );
};

export default BlogSevenContent;
/*
<p className='p-bold'></p>
      <p className='p'></p>
*/
