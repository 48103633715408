import React from "react";
import "../../../stylesheets/BlogContent.css";

const BlogFiveContent = () => {
  return (
    <div className="blog-content">
      <div className="blog-content-container">
        <h1 className="h2 text-black">Struggling with Anxiety Before Job Interview</h1>
        <p className="p">
          We all have been through that feeling when your palms start sweating,
          your heartbeat is out of its normal rhythm, where every second feels
          an hour, where your mind is raising all kinds of self- doubt
          questions. Sounds familiar right? and if it is still not familiar you
          haven’t been in a job interview before so this might help you out.
        </p>
        <p className="p">
          Let’s get to the point. Here is some of my advice on this topic.
        </p>
        <ul className="p">
          <li>
            Don’t “force” yourself to come down. Greet the interviewer with
            confidence. Try to divert your attention, do something which makes
            you feel happy and relaxed. For example, I start listening to some
            soothing music. It helps me calm down as well as diverts my
            attention too
          </li>
          <li>
            Accept the fact that we can’t always control what we will be asked
            or what will happen in the interview, but we can control how we
            prepare for it. You can use your anxiety to motivate yourself to
            prepare in the best possible ways. Knowing that you are well
            prepared for something is always helpful in taking off that pressure
            load
          </li>
          <li>
            Make a note of all your spinning thoughts. List down everything
            that’s popping into your head. Writing down your thoughts can help
            you simplify the ideas and opinions that you want to express in your
            interview
          </li>
          <li>
            Don't let self-doubt get into your mind. Don't ask yourself: “AM I
            WORTH FOR IT’’, Anxiety causes us to become very self-centered and
            self-focused. Control your emotional thoughts with logic? Keep this
            fact in mind that getting selected or not will never define your
            worth
          </li>
          <li>
            Just a few minor details -Try to greet the receptionist at the
            interview site. Ask the interviewer some questions that are popping
            into your head. Don’t just answer the interviewer's questions but
            try to communicate with them
          </li>
        </ul>

        <p className="p">
          At last one of the most important things is don’t fake it and go slow.
          Just go ahead and conquer it.
        </p>
      </div>
    </div>
  );
};

export default BlogFiveContent;
/*
<p className='p-bold'></p>
      <p className='p'></p>
*/
