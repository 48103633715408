import React from "react";
import "../stylesheets/TermsOfUseHome.css";
import TopHeader from "./../../../common/components/TopHeader";
import TopFixedHeader from "./../../../common/components/TopFixedHeader";

function PrivacyStatementHome() {
  return (
    <div className="terms-of-use-home">
      <TopHeader />
      <TopFixedHeader />
      <h2 className="terms-of-use-name h2-heading">Terms of Use</h2>
    </div>
  );
}

export default PrivacyStatementHome;
