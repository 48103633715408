import React from "react";
import "../../../stylesheets/BlogContent.css";

const BlogOneContent = () => {
  return (
    <div className="blog-content">
      <div className="blog-content-container">
      <h1 className="h2 text-black">Digital Marketing vs Traditional Marketing! Which is better in 2021?</h1>
        <p className="p">
          Marketing plays a crucial role in the success of the business. No
          matter how marketing is done, its purpose is to reach a product or
          service to as many people as possible. Today we will know through this
          post, what is Traditional Marketing and Digital Marketing? And which
          is the better choice between Digital Marketing vs Traditional
          Marketing?
        </p>
        <p className="p-bold">What is traditional marketing?</p>
        <p className="p">
          Traditional marketing here means, to sell an item or product i.e. to
          reach the people, adopting those methods which have been going on for
          a long time. Few instances of traditional marketing include newspaper
          means print media, followed by radio, television, pamphlets, banners,
          and announcements, etc. The Internet is not required in this type of
          marketing. It has to be done physically.
        </p>
        <p className="p-bold">What is Digital Marketing?</p>
        <p className="p">
          In this, the internet is used to promote the service offerings of a
          company or its products to the people. Facebook, Google, Youtube,
          E-mail, or any Social media platform on the internet is considered
          suitable for digital marketing. And the marketing done through these
          is called digital marketing.
        </p>
        <p className="p">
          Nowadays the need for digital marketing is proving to be much better
          than traditional marketing. The main reason for this is that nowadays
          almost everyone is becoming an internet user. Everyone has a
          smartphone, any information can be selectively delivered to the
          people.
        </p>
        <p className="p">
          Now we will compare Traditional Marketing and Digital Marketing
        </p>
        <p className="p-bold">Traditional Marketing vs Digital Marketing</p>
        <p className="p">
          Let us take an example and understand that Ram has to do Traditional
          Marketing of a product in Jaipur, Rajasthan and Shyam has to do
          Digital Marketing
        </p>
        <ul className="p">
          <li>
            According to traditional marketing, Ram will have to show
            advertisements all over Rajasthan
          </li>
          <li>Whereas Shyam will show the advertisement only in Jaipur</li>
          <li>
            In traditional marketing, Ram will have to pay to show
            advertisements all over Rajasthan
          </li>
          <li>
            Whereas Shyam will get the money only for showing the advertisement
            in Jaipur
          </li>
          <li>
            In traditional marketing, Ram has to show advertisements in
            different ways, whereas
          </li>
          <li>
            In digital marketing, Shyam can show advertisements at any place
            sitting in one place
          </li>
          <li>
            Ram will have to show advertisements to the whole of Rajasthan again
            and again to show advertisements in Jaipur but Shyam will show them
            only to Jaipur
          </li>
        </ul>
        <p className="p-bold">Conclusion</p>
        <p className="p">
          Overall, traditional marketing can prove to be much more expensive
          than digital marketing. Because if you want to market a product, then
          in traditional marketing you have to show ads to all those people who
          have nothing to do with your product. But in digital marketing, you
          can show ads only to the targeted customers.
        </p>
        <p className="p">
          So, readers, I hope that you must have understood what is the
          difference between traditional marketing and digital marketing? If you
          have to choose between Digital Marketing and Traditional Marketing
          then you should go with Digital Marketing
        </p>

        <p className="p">
          If you like this post of ours, then do not forget to share it on
          social media and comment below in the contact us section for any kind
          of question or suggestion in your mind.
        </p>
      </div>
    </div>
  );
};

export default BlogOneContent;
/*
<p className='p-bold'></p>
      <p className='p'></p>
*/
