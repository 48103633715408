import React from "react";
import TermsOfUseContent from "./components/TermsOfUseContent";
import TermsOfUseHome from "./components/TermsOfUseHome";
import { Helmet } from 'react-helmet-async';

function TermsOfUse() {
  return (
    <>
    <Helmet>
        <title>Terms of Use | Lejhro</title>
        <meta name="description" content="Terms of use have been laid to ensure that there is no confusion or false commitment from both sides and that the services and commitments offered are clear." />
        <meta name="keywords" content="Terms of Use, Lejhro"/>
      </Helmet>
      <TermsOfUseHome/>
      <TermsOfUseContent/>
    </>
  );
}

export default TermsOfUse;
