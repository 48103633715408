import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "./App.css";

import ScrollToTop from "./common/components/ScrollToTop";
import Homepage from "./pages/HomePage/Homepage";
import Innovations from "./pages/InnovationsPage/Innovations";
import Services from "./pages/ServicesPage/Services";
import AboutUs from "./pages/AboutUsPage/AboutUs";
import TermsAndConditions from "./pages/TermsOfUsePage/TermsOfUse";
import PrivacyStatement from "./pages/PrivacyStatementPage/PrivacyStatement";
import Contact from "./pages/ContactUsPage/Contact";
import FooterComponent from "./common/components/FooterComponent";
import FinancialServices from "./pages/FinancialServices/FinancialServices";
import BlogOne from "./pages/BlogPage/Blogs/BlogOne/BlogOne";
import BlogPage from "./pages/BlogPage/BlogPage";
import BlogTwo from "./pages/BlogPage/Blogs/BlogTwo/BlogTwo";
import BlogThree from "./pages/BlogPage/Blogs/BlogThree/BlogThree";
import BlogFour from "./pages/BlogPage/Blogs/BlogFour/BlogFour";
import BlogFive from "./pages/BlogPage/Blogs/BlogFive/BlogFive";
import BlogSix from "./pages/BlogPage/Blogs/BlogSix/BlogSix";
import BlogSeven from "./pages/BlogPage/Blogs/BlogSeven/BlogSeven";
import BlogEight from "./pages/BlogPage/Blogs/BlogEight/BlogEight";
import BlogNine from "./pages/BlogPage/Blogs/BlogNine/BlogNine";
import BlogTen from "./pages/BlogPage/Blogs/BlogTen/BlogTen";
import BlogEleven from "./pages/BlogPage/Blogs/BlogEleven/BlogEleven";
import BlogTwelve from "./pages/BlogPage/Blogs/BlogTwelve/BlogTwelve";
import BlogThirteen from "./pages/BlogPage/Blogs/BlogThirteen/BlogThirteen";
import BlogFourteen from "./pages/BlogPage/Blogs/BlogFourteen/BlogFourteen";
import BlogFifteen from "./pages/BlogPage/Blogs/BlogFifteen/BlogFifteen";
import BlogSixteen from "./pages/BlogPage/Blogs/BlogSixteen/BlogSixteen";
import BlogSeventeen from "./pages/BlogPage/Blogs/BlogSeventeen/BlogSeventeen";
import BlogEighteen from "./pages/BlogPage/Blogs/BlogEighteen/BlogEighteen";
import BlogNineteen from "./pages/BlogPage/Blogs/BlogNineteen/BlogNineteen";
import BlogTwenty from "./pages/BlogPage/Blogs/BlogTwenty/BlogTwenty";
import BlogTwentyOne from "./pages/BlogPage/Blogs/BlogTwentyOne/BlogTwentyOne";
import BlogTwentyTwo from "./pages/BlogPage/Blogs/BlogTwentyTwo/BlogTwentyTwo";
import BlogTwentyThree from "./pages/BlogPage/Blogs/BlogTwentyThree/BlogTwentyThree";
import BlogTwentyFour from "./pages/BlogPage/Blogs/BlogTwentyFour/BlogTwentyFour";
import BlogTwentyFive from "./pages/BlogPage/Blogs/BlogTwentyFive/BlogTwentyFive";
import BlogTwentySix from "./pages/BlogPage/Blogs/BlogTwentySix/BlogTwentySix";
import BlogTwentySeven from "./pages/BlogPage/Blogs/BlogTwentySeven/BlogTwentySeven";
import BlogTwentyEight from "./pages/BlogPage/Blogs/BlogTwentyEight/BlogTwentyEight";
import BlogTwentyNine from "./pages/BlogPage/Blogs/BlogTwentyNine/BlogTwentyNine";
import BlogThirty from "./pages/BlogPage/Blogs/BlogThirty/BlogThirty";
import AOS from "aos";
import BlogThirtyOne from "./pages/BlogPage/Blogs/BlogThirtyOne/BlogThirtyOone";
import BlogThirtyTwo from "./pages/BlogPage/Blogs/BlogThirtyTwo/BlogThirtyTwo";
import BlogThirtyThree from "./pages/BlogPage/Blogs/BlogThirtyThree/BlogThirtyThree";
import BlogThirtyFour from "./pages/BlogPage/Blogs/BlogThirtyFour/BlogThirtyFour";
import BlogThirtyFive from "./pages/BlogPage/Blogs/BlogThirtyFive/BlogThirtyFive";
import BlogThirtySix from "./pages/BlogPage/Blogs/BlogThirtySix/BlogThirtySix";
import BlogThirtySeven from "./pages/BlogPage/Blogs/BlogThirtySeven/BlogThirtySeven";
import BlogThirtyEight from "./pages/BlogPage/Blogs/BlogThirtyEight/BlogThirtyEight";
import BlogThirtyNine from "./pages/BlogPage/Blogs/BlogThirtyNine/BlogThirtyNine";
function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      delay: 12,
      throttleDelay: 99,
    });
  }, []);
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/innovations" element={<Innovations />} />
          <Route path="/business-services" element={<Services />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/privacy-statement" element={<PrivacyStatement />} />
          <Route path="/terms-of-use" element={<TermsAndConditions />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path="/financial-services" element={<FinancialServices />} />
          <Route
            path="/blogs/digitalmarketing-vs-traditional-markeing"
            element={<BlogOne />}
          />
          <Route
            path="/blogs/5-things-that-tell-you-to-pursue-a-different-career"
            element={<BlogTwo />}
          />
          <Route
            path="/blogs/things-not-to-put-on-your-cv"
            element={<BlogThree />}
          />
          <Route
            path="/blogs/how-to-take-a-career-break-without-it-hurting-your-career"
            element={<BlogFour />}
          />
          <Route
            path="/blogs/struggling-with-anxiety-before-job-interview"
            element={<BlogFive />}
          />
          <Route
            path="/blogs/recruiting-through-employer-branding"
            element={<BlogSix />}
          />
          <Route
            path="/blogs/artificial-intelligence"
            element={<BlogSeven />}
          />
          <Route path="/blogs/blockchain" element={<BlogEight />} />
          <Route path="/blogs/internet-of-things" element={<BlogNine />} />
          <Route
            path="/blogs/advanced-python-concepts-that-you-must-know"
            element={<BlogTen />}
          />
          <Route
            path="/blogs/career-in-data-science"
            element={<BlogEleven />}
          />
          <Route
            path="/blogs/data-analysis-everything-you-need-to-know"
            element={<BlogTwelve />}
          />
          <Route path="/blogs/data-analytics-tool" element={<BlogThirteen />} />
          <Route
            path="/blogs/data-science-vs-data-analytics"
            element={<BlogFourteen />}
          />
          <Route path="/blogs/data-storytelling" element={<BlogFifteen />} />
          <Route
            path="/blogs/data-visualization-tools-and-techniques"
            element={<BlogSixteen />}
          />
          <Route
            path="/blogs/explore-the-world-of-data"
            element={<BlogSeventeen />}
          />
          <Route
            path="/blogs/how-are-programming-languages-important-to-us"
            element={<BlogEighteen />}
          />

          <Route
            path="/blogs/key-data-science-trends-for-2022-2025"
            element={<BlogNineteen />}
          />
          <Route
            path="/blogs/content-marketing-metrics"
            element={<BlogTwenty />}
          />

          <Route
            path="/blogs/ethics-in-data-science"
            element={<BlogTwentyOne />}
          />

          <Route
            path="/blogs/guide-to-build-ml-models-with-apache-spark"
            element={<BlogTwentyTwo />}
          />

          <Route
            path="/blogs/how-to-create-a-killer-seo-strategy-for-your-website"
            element={<BlogTwentyThree />}
          />

          <Route
            path="/blogs/how-to-do-competitive-seo-analysis-an-overview"
            element={<BlogTwentyFour />}
          />

          <Route
            path="/blogs/data-visualization-to-communicate-insights-and-tell-a-story"
            element={<BlogTwentyFive />}
          />

          <Route
            path="/blogs/revolutionizing-healthcare-the-power-of-data-science"
            element={<BlogTwentySix />}
          />

          <Route
            path="/blogs/common-challenges-of-data-scientists-in-analyzing-data"
            element={<BlogTwentySeven />}
          />

          <Route
            path="/blogs/land-your-career-in-python-tips-guidance"
            element={<BlogTwentyEight />}
          />

          <Route
            path="/blogs/discover-the-incredible-applications-of-sentiment-analysis"
            element={<BlogTwentyNine />}
          />

          <Route
            path="/blogs/essential-email-marketing-metrics-you-need-to-know-in-2023"
            element={<BlogThirty />}
          />

          <Route
            path="/blogs/modern-data-analysis-principles-the-laws-of-data-science"
            element={<BlogThirtyOne />}
          />

          <Route
            path="/blogs/python-variables-a-comprehensive-guide-with-example"
            element={<BlogThirtyTwo />}
          />

          <Route
            path="/blogs/marketing-automation-a-complete-wrap-up"
            element={<BlogThirtyThree />}
          />

          <Route
            path="/blogs/data-warehousing-revolution-future-with-ai-and-ml"
            element={<BlogThirtyFour />}
          />

          <Route
            path="/blogs/social-media-boost-biz-growth-with-engaging-content"
            element={<BlogThirtyFive />}
          />

          <Route
            path="/blogs/7-proven-strategies-for-digital-marketing-success"
            element={<BlogThirtySix />}
          />

          <Route
            path="/blogs/achieving-excellence-in-data-governance-and-data-quality-management"
            element={<BlogThirtySeven />}
          />

          <Route
            path="/blogs/how-tech-skills-propel-success"
            element={<BlogThirtyEight />}
          />

          <Route
            path="/blogs/statistical-approaches-for-data-privacy"
            element={<BlogThirtyNine />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <FooterComponent />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
