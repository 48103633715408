
import React from "react";
import BlogTwentyContent from "./components/BlogTwentyOneContent";
import TopFixedHeader from '../../../../common/components/TopFixedHeader';
import BlogPageHome from '../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogTwentyOne() {
  return (
    <>
      <Helmet>
        <title>Ethics In Data Science | Lejhro
        </title>
        <meta name="description" content="Explore the importance of ethics in data science. Learn how to implement responsible data practices and mitigate potential harm." />
        <meta name="keywords" content="Ethics, Data science, Data Ethics, Privacy, Transparency"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogTwentyContent/>
    </>
  );
}

export default BlogTwentyOne;