import { Container } from "@mui/material";
import React from "react";
import "../stylesheets/introduction.css";
import { Row, Col } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndianRupeeSign, faCoins, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';

function Introduction() {
    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: '#3a3632'
        // height: "100vh", // Set the container height to fill the viewport
    };

    const headingStyle = {
        fontSize: "34px",
        textAlign: "center",
        color: '#3a3632',
        marginTop: '3rem'
    };

    const paragraphStyle = {
        fontSize: "18px",
        textAlign: "center",
        color: '#3a3632'
    };
    const rowStyle = {
        display: "flex",
        justifyContent: "center",
        gap: "150px", // Adjust the gap between columns as needed
        marginTop: '3rem',
        paddingBottom: '3rem'
    };

    const colStyle = {
        color: "#3a3632",
        width: "280px", // Set your custom width for the columns
        // textAlign: "center",
    };


    return (
        <div style={{ backgroundColor: "#f2f2f2" }}>
            <Container>
                <div className="intro" style={containerStyle}>
                    <h1 style={headingStyle}>Fixed Deposits</h1>
                    <p className="ques" style={paragraphStyle}>Witness the transformation of your savings into a thriving force of financial growth with a powerful choice of Fixed Deposits!</p>
                    <p style={paragraphStyle}>By entrusting your funds to FD, you're setting in motion a process that nurtures your wealth. It's not about safeguarding your hard-earned money; it's about igniting a journey toward prosperous returns.</p>
                    <h1 className='ques' style={headingStyle}>Features & Benefits</h1>
                    <p style={paragraphStyle}>Trusted by millions of customers, Lejhro Fixed Deposit is the safest investment option with attractive interest rates.<br></br>
                    </p>
                </div>
                <Row style={rowStyle}>
                <div className="first">
                    <Col style={colStyle}>
                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: '1rem' }}>
                            <FontAwesomeIcon icon={faMoneyBillTransfer} size="5x" style={{ color: "#ffb10a" }} />
                        </div>
                        <h3 style={{ fontSize: '20px', fontWeight: '600', color: "#3a3632", textAlign: 'center', display: 'flex', justifyContent: "center" }}>Simple and Transparent</h3>
                        <p style={{ fontSize: '18px', color: '#3a3632', textAlign: 'center', display: 'flex' }}>We have an easy-to-use platform where you get a dedicated wealth manager to build and maintain your portfolio.</p>
                    </Col>
                    </div>
                    <Col style={colStyle}>
                        <div className="icon1515">
                            <div style={{ textAlign: 'center', display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: '1rem' }}>
                                <FontAwesomeIcon icon={faCoins} size="5x" style={{ color: "#ffb10a" }} />
                            </div>
                            <h3 style={{ fontSize: '20px', fontWeight: '600', color: "#3a3632", textAlign: 'center', display: 'flex', justifyContent: "center" }}>Greater Returns</h3>
                            <p style={{ fontSize: '18px', color: '#3a3632', textAlign: 'center', display: 'flex' }}>Multiply your savings safely and smartly by earning 2 times more than your FD returns</p>
                        </div>
                    </Col>
                    <div className="lol12">
                    <Col style={colStyle}>
                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: '1rem' }}>
                            <FontAwesomeIcon icon={faIndianRupeeSign} size="5x" style={{ color: "#ffb10a" }} />
                        </div>
                        <h3 style={{ fontSize: '20px', fontWeight: '600', color: "#3a3632", textAlign: 'center', display: 'flex', justifyContent: "center" }}>Monthly Cash Flow</h3>
                        <p style={{ fontSize: '18px', color: '#3a3632', textAlign: 'center', display: 'flex' }}>Invest and get your passive income seamlessly through the monthly repayments. No hidden charges included</p>
                    </Col>
                    </div>
                </Row >


            </Container >
        </div>
    );
}

export default Introduction;