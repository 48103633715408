import React from "react";
import "../../../stylesheets/BlogContent.css";

const BlogSixContent = () => {
  return (
    <div className="blog-content">
      <div className="blog-content-container">
      <h1 className="h2 text-black">Note Down the Importance of a Strong Employer Brand</h1>
        <p className="p">
          Recruiting through Employer Branding is cosnsidered a necessity these
          days, if you want to grow your company on a Big Scale.
        </p>
        <p className="p">
          For you, Let me mention Some Key pointers which will tell you the
          actual importance of Employer Branding.
        </p>

        <ul className="p">
          <li>
            <p className="p-bold mrz-pz">Quality Employees</p>
            <p className="p">
              True it is said, “Good attracts Good”, See if you are a big market
              leader, automatically you will attract a quality recruit in your
              organization. In turn, it would bring huge ROI for your
              organization in the long term
            </p>
          </li>
          <li>
            <p className="p-bold mrz-pz">Lesser salary expenses on employees</p>
            <p className="p">
              See if you have great recognition in the market then candidates
              will try working in your company naturally. According to recent
              reports, 67% of the employees work even after getting less pay
              just because the company they are in has a huge face value
            </p>
          </li>
          <li>
            <p className="p-bold mrz-pz">Retaining the right talent</p>
            <p className="p">
              Your employees are very easy to retain with you for a long tenure
              if the work culture is very nice in your company and it’s a fact
              most employees quit because of the work culture of the company
              itself and not the work
            </p>
          </li>
          <li>
            <p className="p-bold mrz-pz">Best for customer credibility</p>
            <p className="p">
              According to research 80% of the customers recommend the things
              that they love, to their known people. By attracting and
              recruiting the best employees in your company you already set a
              great customer experience because then the customers will be
              dealing directly with the best talents in your organization
            </p>
          </li>
        </ul>
        <p className="p-bold">Final Thoughts</p>
        <p className="p">
          Finally, you must understand the importance of employer branding and
          how, not only the HR and the Recruitment team but also the CEO and
          Marketing team play a major role in the overall success of a brand.
          Employer Branding not only supports Employee retention and Quality
          recruits in the company but also adds up many positive changes in all
          aspects of the organization.
        </p>
      </div>
    </div>
  );
};

export default BlogSixContent;
/*
<p className='p-bold'></p>
      <p className='p'></p>
*/
