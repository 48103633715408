import React from "react";
import "../stylesheet/InnovationHomescreen.css";
import TopHeader from "../../../common/components/TopHeader";

function InnovationHomescreen() {
  return (
    <div className="innovation-homescreen">
      <TopHeader />
      <div className="innovation-content-container">
      <div className="innovation-home-img" id="innovation-home-img">
        <img src="images/lejhro-innovation-page1.png" alt="" />
      </div>
      <div className="innovation-home-content" id="innovation-home-content">
        <h1 className="h2-heading">Driving Innovation Through Change</h1>
      </div>
      </div>
    </div>
  );
}

export default InnovationHomescreen;
