import React from "react";
import "../stylesheets/PrivacyStatementHome.css";
import TopHeader from "./../../../common/components/TopHeader";
import TopFixedHeader from "./../../../common/components/TopFixedHeader";

function PrivacyStatementHome() {
  return (
    <div className="privacy-statement-home">
      <TopHeader />
      <TopFixedHeader />
      <h2 className="privacy-statement-name h2-heading">Privacy Statement</h2>
    </div>
  );
}

export default PrivacyStatementHome;
