import React, { useState } from "react";
import "../stylesheets/ContactForm.css";
import axios from "axios";
import { Spin, Button, Form } from "antd";
import Links from "../../../data/Links.json";

const ContactForm = () => {
  const { LejhroHubspotFormPortalID, LejhroHubspotFormGuid } = Links;
  const [input1Value, setInput1Value] = useState(null);
  const [input1Error, setInput1Error] = useState(null);
  const [input2Value, setInput2Value] = useState(null);
  const [input2Error, setInput2Error] = useState(null);
  const [input3Value, setInput3Value] = useState(null);
  const [input3Error, setInput3Error] = useState(null);
  const [input4Value, setInput4Value] = useState(null);
  const [input5Error, setInput5Error] = useState(null);
  const [input5Value, setInput5Value] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isForm, setIsForm] = useState(true);
  const [result, setResult] = useState("");

  const submitForm = async () => {
    let hasErrors = false;
    if (!validateEmail(input1Value)) {
      setInput1Error("Please enter a valid Email ID e.g.:abc@abc.com");
      hasErrors = true;
    }
    if (!validateText(input2Value)) {
      setInput2Error("Please check the first name you have entered");
      hasErrors = true;
    }
    if (!validateText(input3Value)) {
      setInput3Error("Please check the mobile number you have entered");
      hasErrors = true;
    }
    if (!validateText(input5Value)) {
      setInput5Error("Please check the last name you have entered");
      hasErrors = true;
    }
    // console.log(hasErrors);
    if (hasErrors) {
      return null;
    }
    setIsSubmitting(true);
    const portalId = LejhroHubspotFormPortalID; //"24964241";
    const formGuid = LejhroHubspotFormGuid; //"c5f94f2f-d34e-46c0-95cf-e36538d47a91";
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    try {
      const response = await axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        {
          portalId,
          formGuid,
          fields: [
            {
              name: "firstname",
              value: input2Value
            },
            {
              name: "lastname",
              value: input5Value
            },
            {
              name: "email",
              value: input1Value
            },
            {
              name: "phone",
              value: input3Value
            },
            {
              name: "company_name",
              value: input4Value || "NA"
            }
          ]
        },
        config
      );
      if (response.data) {
        setIsSubmitting(false);
        setIsForm(false);
        setResult(
          "Thanks for contacting us. Concern Team will reach out to you."
        );
      }
    } catch (err) {
      console.log(err);
      setIsSubmitting(false);
      setIsForm(false);
      setResult("Some Error Occurred!. Please try again");
    }
  };

  const validateEmail = values => {
    if (!values || values === "") {
      return false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values)) {
      return false;
    }
    return true;
  };
  const validateText = value => {
    if (!value || value === "") {
      return false;
    }
    return true;
  };
  return (
    <Spin spinning={isSubmitting}>
      {isForm &&
        <Form className="contact-form" onFinish={submitForm}>
          <div
            className={
              input2Error === null
                ? "contact-form-field-holder"
                : "contact-form-field-holder-error contact-form-field-holder"
            }
          >
            <input
              type="text"
              id="name"
              onChange={e => {
                setInput2Value(e.target.value);
              }}
              onBlur={() => {
                !validateText(input2Value)
                  ? setInput2Error(
                      "Please check the fist name you have entered"
                    )
                  : setInput2Error(null);
              }}
              className="p"
            />
            <label
              htmlFor="name"
              className={input2Value ? "contact-form-field-label-text" : ""}
            >
              FIRST NAME <span>*</span>{" "}
            </label>
            <p
              className={
                input2Error === null ? "hidden" : "contact-form-error-p"
              }
            >
              {input2Error}
            </p>
          </div>
          <div
            className={
              input5Error === null
                ? "contact-form-field-holder"
                : "contact-form-field-holder-error contact-form-field-holder"
            }
          >
            <input
              type="text"
              id="lastName"
              onChange={e => {
                setInput5Value(e.target.value);
              }}
              onBlur={() => {
                !validateText(input2Value)
                  ? setInput5Error(
                      "Please check the last name you have entered"
                    )
                  : setInput5Error(null);
              }}
              className="p"
            />
            <label
              htmlFor="lastName"
              className={input5Value ? "contact-form-field-label-text" : ""}
            >
              LAST NAME <span>*</span>{" "}
            </label>
            <p
              className={
                input5Error === null ? "hidden" : "contact-form-error-p"
              }
            >
              {input5Error}
            </p>
          </div>

          <div
            className={
              input1Error === null
                ? "contact-form-field-holder"
                : "contact-form-field-holder-error contact-form-field-holder"
            }
          >
            <input
              type="text"
              id="email"
              onChange={e => {
                setInput1Value(e.target.value);
              }}
              onBlur={() => {
                !validateEmail(input1Value)
                  ? setInput1Error(
                      "Please enter a valid Email ID e.g.:abc@abc.com"
                    )
                  : setInput1Error(null);
              }}
              className="p"
            />
            <label
              htmlFor="email"
              className={input1Value ? "contact-form-field-label-text" : ""}
            >
              EMAIL <span>*</span>
            </label>
            <p
              className={
                input1Error === null ? "hidden" : "contact-form-error-p"
              }
            >
              {input1Error}
            </p>
          </div>

          <div
            className={
              input3Error === null
                ? "contact-form-field-holder"
                : "contact-form-field-holder-error contact-form-field-holder"
            }
          >
            <input
              type="tel"
              id="mobilenumber"
              onChange={e => {
                setInput3Value(e.target.value);
              }}
              onBlur={() => {
                !validateText(input3Value)
                  ? setInput3Error(
                      "Please check the mobile number you have entered"
                    )
                  : setInput3Error(null);
              }}
              className="p"
            />
            <label
              htmlFor="mobilenumber"
              className={input3Value ? "contact-form-field-label-text" : ""}
            >
              PHONE NUMBER <span>*</span>
            </label>
            <p
              className={
                input3Error === null ? "hidden" : "contact-form-error-p"
              }
            >
              {input3Error}
            </p>
          </div>

          <div className="contact-form-field-holder">
            <input
              type="text"
              id="company"
              onChange={e => {
                setInput4Value(e.target.value);
              }}
              onBlur={() => {}}
              className="p"
            />
            <label
              htmlFor="company"
              className={input4Value ? "contact-form-field-label-text" : ""}
            >
              COMPANY NAME{" "}
            </label>
          </div>
          <div className="contact-form-button-holder">
            <Button
              type="primary"
              htmlType="submit"
              style={{ background: "#dd3b30" }}
            >
              SUBMIT
            </Button>
          </div>
        </Form>}
      {!isForm &&
        <p>
          {result}
        </p>}
    </Spin>
  );
};

export default ContactForm;
