import React from "react";
import "../../../stylesheets/BlogContent.css";
import Links from "../../../../../data/Links.json";

const BlogFifteenContent = () => {
  const { LejhroBootcampURL } = Links;
  return (
    <div className="blog-content">
      <div className="blog-content-container">
        <h1 className="h2 text-black">Data Storytelling</h1>
        <p className="p-bold">
          Storytelling with Data: How to Communicate Visually and Effectively?
        </p>

        <p className="p">
          Over the past few years, data storytelling has taken off. This is not
          only because data and the role it plays in our lives is increasing.
          It’s also because storytelling is the way we as humans have always
          come to understand ourselves and the world we live in.
        </p>
        <p className="p-bold">What is Data Storytelling?</p>

        <p className="p">
          The phrase “data storytelling” has been associated with many
          things—data visualisations, infographics, dashboards, data
          presentations, and so on. Too often data storytelling is interpreted
          as just visualising data effectively, however, it is much more than
          just creating visually-appealing data charts. Data storytelling is a
          structured approach for communicating data insights, and it involves a
          combination of three key elements: data, visuals, and narrative.
        </p>
        <p className="p">
          Data storytelling is the best way to use data to create new knowledge
          and new decisions or actions. It is an integrative practice that
          incorporates knowledge and skills from several disciplines, including
          communication, analysis and design. It is practised across many fields
          and is used to address a variety of challenges.
        </p>
        <p className="p-bold">Good data stories have three main elements:</p>

        <ul className="p">
          <li>Data</li>
          <li>Visuals</li>
          <li>Narrative</li>
        </ul>
        <p className="p">
          Together, these elements put your data into context and pull the most
          important information into focus for key decision-makers.
        </p>

        <p className="p-bold">Why is it important?</p>
        <p className="p">
          Every business wants to make good decisions. And good decisions rely
          on good information. This is why understanding and translating data
          into meaningful insights is crucial. However, if you aren’t connecting
          that information to your audience, they will have little motivation to
          act on it. That’s where data storytelling comes in.
        </p>
        <p className="p">
          Data stories help you communicate key insights clearly and
          compellingly—driving change and inspiring action in business.
        </p>
        <p className="p-bold">How do you make a good data story?</p>
        <ul className="p">
          <li>
            <p className="p">
              <b>Identify your story</b>
            </p>
            <p className="p">
              The first step to telling a good data story is to uncover a story
              worth telling. You can start by asking a question or forming a
              hypothesis, then compiling and digging into relevant data to find
              answers.
            </p>
          </li>
          <li>
            <p className="p">
              <b>Keep it relevant to the audience</b>
            </p>
            <p className="p">
              This means the content (including copy and visuals) needs to fit
              with the audience’s current level of knowledge and it needs to
              help them reach a goal of some kind.
            </p>
          </li>
          <li>
            <p className="p">
              <b>It needs to include good data</b>
            </p>
            <p className="p">
              This means the data should be from a reputable source and/or
              collected in a way that truly represents what’s needed to tell a
              truthful story.
            </p>
            <p className="p">
              Publicly available data from government entities,
              intergovernmental organisations, academic researchers, and
              established leaders in the analysis are not only more accessible,
              but are also transparent and verifiable.
            </p>

            <p className="p">
              Since data is the centre point, these stories are not about what
              you think your audience should hear, they are about sharing what
              the objective data says. This is markedly different from other
              stories we might tell.
            </p>
          </li>
          <li>
            <p className="p">
              <b>There needs to be a clear narrative</b>
            </p>
            <p className="p">
              When it comes to storytelling, we are all used to a traditional
              story arc with a beginning, middle, and end. For data stories,
              this usually means you need an introduction to the topic before
              you dive into the data. You also need to conclude with a specific
              call to action—this is another thing that makes a data story
              distinctly different from a straightforward report.
            </p>
          </li>
          <li>
            <p className="p">
              <b>Use visuals to present and clarify your message</b>
            </p>
            <p className="p">
              Finally, a good data story needs visuals. Visuals are a powerful
              way to engage your audience and improve retention—especially when
              communicating with non-technical audiences. Visualising your data
              story enhances comprehension at every level. Storytelling with
              data visualisation helps you simplify the information, highlight
              the most important data, and communicate key points quickly.{" "}
            </p>
          </li>
        </ul>
        <p className="p">
          There are many ways to visualise your data including:
        </p>
        <ul className="p">
          <li>Flowcharts</li>
          <li>Bar graphs</li>
          <li>Infographics</li>
          <li>Road maps</li>
          <li>Pie charts</li>
          <li>Scatterplots</li>
        </ul>
        <p className="p">
          Choose visuals that will make it easiest for your audience to
          understand and engage with the data. Make use of data visualisation
          tools and learn the art of data storytelling with Lejhro’s boot camp.
        </p>

        <p className="p">
          Build up your career with the help of the Data Analysis boot camp by
          Lejhro. It not only gives you an amazing learning experience but also
          provides students with a 3-month internship after the Bootcamp by
          industry experts.
        </p>

        <p className="p">
          So{" "}
          <a href={LejhroBootcampURL} target="_blank" rel="noreferrer">
            enrol
          </a>{" "}
          yourself in the program and kickstart your career in the field of data
          analysis.
        </p>
      </div>
    </div>
  );
};

export default BlogFifteenContent;
/*
<p className='p-bold'></p>
      <p className='p'></p>
*/
