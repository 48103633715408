import React from "react";
import "../../../stylesheets/BlogContent.css";
import Links from "../../../../../data/Links.json";

const BlogFourteenContent = () => {
  const { LejhroBootcampURL } = Links;
  return (
    <div className="blog-content">
      <div className="blog-content-container">
        <h1 className="h2 text-black">Data Science Vs Data Analytics</h1>
        <p className="p">
          In today's world, Data is a big deal and so is Data science and data
          analytics. The fact of the matter is that Data literacy is still
          fairly low and even literate people are not very well aware of the
          difference between Data Science and Data Analytics. Therefore, it is
          absolutely imperative to understand both concepts clearly.
        </p>
        <p className="p">
          Data science in very simple terms is the process of changing raw data
          into a new form which can add meaning and value to businesses. On the
          other hand, Data Analytics is the process of examining data,
          interpreting and analysing it in order to understand it thoroughly and
          thereby make crucial business decisions based upon it.
        </p>
        <p className="p-bold">Key Differences :</p>
        <p className="p">
          Massive data is being produced by both small and big companies, which
          mostly goes obsolete. Therefore, the integration of data science and
          its intermediary processes into business enterprises made collection,
          analysis as well as interpretation of data possible which in turn aids
          businesses and other organisations to make correct, precise and
          rational decisions which boost their growth.
        </p>
        <p className="p">
          Data is used to formulate new strategies, solve complex problems and
          even aid in understanding the employees and other parties involved in
          the business.
        </p>
        <p className="p">
          Data science consists of a few subgroups like Artificial intelligence,
          data analytics, machine learning etc.
        </p>
        <p className="p-bold">Elucidation :</p>
        <p className="p">
          The comprehensive process of extracting relevant information from
          large raw data sets is called Data Science. It is a continuous process
          which is used while launching a product, formulating a marketing
          strategy as well as making important business decisions.
        </p>
        <p className="p">
          While Data Analysis is a business concept that examines large data
          sets to provide answers to complex problems, guiding them to make
          better choices and analyse the product market, etc. Therefore a
          combination of both Data Science, as well as Data Analytics, is a
          lethal weapon that uses data to offer a competitive edge over others.
        </p>
        <p className="p-bold">Application :</p>
        <p className="p">
          Data Science: Employed to conduct activities like Statistical
          modelling, data wrangling and programming.
        </p>
        <p className="p">
          Data Analysis: Employed for risk management, budgeting, forecasting,
          marketing and sales, product development and product management.
        </p>
        <p className="p-bold">Techniques :</p>
        <p className="p">
          Data Science techniques- Data mining, data engineering, data sorting.
        </p>
        <p className="p">
          Data Analysis- Descriptive, diagnostic, predictive and prescriptive
          analysis techniques are employed.
        </p>
        <p className="p">
          Build up your career with the help of the Data Analysis & Data Science
          boot camp by Lejhro. It not only gives you an amazing learning
          experience but also provides students with a 3-month internship after
          the Bootcamp by industry experts.
        </p>
        <p className="p">
          So{" "}
          <a href={LejhroBootcampURL} target="_blank" rel="noreferrer">
            enrol
          </a>{" "}
          yourself in the program and kickstart your career!
        </p>
      </div>
    </div>
  );
};

export default BlogFourteenContent;
/*
<p className='p-bold'></p>
      <p className='p'></p>
*/
