
import React from "react";
import BlogTwentyContent from "./components/BlogTwentyContent";
import TopFixedHeader from '../../../../common/components/TopFixedHeader';
import BlogPageHome from '../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogTwentyFive() {
  return (
    <>
      <Helmet>
        <title>Data Visualization to Communicate Insights and Tell a Story  | Lehro
        </title>
        <meta name="description" content="Learn how to effectively use data visualization to communicate insights and tell compelling stories. Enhance your data-driven decision-making." />
        <meta name="keywords" content="Data visualization, insights, communication, storytelling, charts, infographics"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogTwentyContent/>
    </>
  );
}

export default BlogTwentyFive;



        // {
        //     "title" : "Data Visualization to Communicate Insights and Tell a Story ",
        //     "content":"Learn how to effectively use data visualization to communicate insights and tell compelling stories. Enhance your data-driven decision-making.",

        
        //   "link":"/blogs/data-visualization-to-communicate-insights-and-tell-a-story"
        // },