import React from "react";
import BlogEightContent from "./components/BlogEightContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogEight() {
  return (
    <>
      <Helmet>
        <title>Blockchain | Lejhro</title>
        <meta name="description" content="Thrive into the detailed figures of blockchain technologies in digitizing transactions in business. Know more innumerable facts on blockchains right in here." />
        <meta name="keywords" content="BlockchainHub, Blockchain News, Cryptography, Recording transactions, BTC wires, Tokenizing"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogEightContent/>
    </>
  );
}

export default BlogEight;
