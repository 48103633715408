import React from "react";
import "../../../stylesheets/BlogContent.css";

const BlogFourContent = () => {
  return (
    <div className="blog-content">
      <div className="blog-content-container">
      <h1 className="h2 text-black">How To Take a Career Break Without It Hurting Your Career</h1>
        <p className="p">
          Taking a career break can seem daunting at first, but it could be one
          of the best decisions you ever make. Career-wise, a break could help
          you to up-skill, find motivation, or even set you off on a whole new
          path.
        </p>
        <p className="p">
          And you may be surprised to discover that career breaks are actually
          encouraged by some employers.
        </p>
        <p className="p">
          Many career breakers worry that taking a career break will leave them
          behind everyone else - or even that they'll drop a couple of rungs on
          the ladder.
        </p>
        <p className="p">
          Secondly, you can ensure your career break isn't 'time off' it's 'time
          out' - in other words, time spent doing useful and constructive
          things.
        </p>
        <p className="p-bold">Here are some tips</p>
        <ul className="p">
          <li>
            Do some volunteer work in a role you aren't doing in your day job.
            For example, if you want to progress to being a manager, but no-one
            seems to want to give you the opportunity, take a volunteer role
            where you will be in charge.
          </li>
          <li>
            If you want to learn new management skills but volunteering isn't
            for you, consider TEFL (teaching English) or training as an
            instructor (skiing, snowboarding, sailing, diving, etc). In a
            teaching or instructing role, you will be effectively be managing a
            group, and also responsible for getting the best performance out of
            them.
          </li>
          <li>
            Taking a career break, no matter what you do, shows you have drive
            and initiative, plus it develops your confidence.
          </li>
        </ul>
        <p className="p">
          You can rest assured that having a career break on your CV is fairly
          common. Whether you’ve been in work for a long time or are just
          starting out.
        </p>
        <p className="p">
          Employers are generally understanding that careers don’t always run
          smoothly. In fact, a number of sectors now offer ‘returnship schemes’
          to help those wanting to get back to work.
        </p>

        <p className="p">
          As long as you prepare to answer any questions about the career break
          on your CV and are confident in showing how the time out was a
          positive thing, the break shouldn’t hinder you from advancing in your
          career. It might even make you stand out from the crowd. Most people
          take time away from work at some point in their careers and all for a
          variety of different reasons. Having a break from employment shouldn’t
          put you off applying for a new role. In fact, it could work to your
          advantage if you know how to make the most of it.
        </p>
      </div>
    </div>
  );
};

export default BlogFourContent;
/*
<p className='p-bold'></p>
      <p className='p'></p>
*/
