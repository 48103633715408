import React from "react";
import { Helmet } from 'react-helmet-async';
import InnovationHomescreen from "./components/InnovationHomescreen";
import InnovationResearch from "./components/InnovationResearch";
import InnovationRD from "./components/InnovationR&D";
import InnovationProductDevelopment from "./components/InnovationProductDevelopment";
import InnovationProductDevelopmentContent from "./components/InnovationProductDevelopmentContent"
import TopFixedHeader from "./../../common/components/TopFixedHeader";


function Innovations() {
  return (
    <>
    <Helmet>
        <title>Lejhro | Innovations</title>
        <meta name="description" content="Our aim is to empower people to innovate exceptional solutions in bringing a revolution in the industry with our rebellious spirit and out-of-the-box thinking." />
        <meta name="keywords" content="Innovation, Lejhro, Change"/>
      </Helmet>
      <TopFixedHeader />
      <InnovationHomescreen />
      <InnovationResearch />
      <InnovationRD/>
      <InnovationProductDevelopment/>
      <InnovationProductDevelopmentContent/>
    </>
  );
}

export default Innovations;
