import React from "react";
import "../../../stylesheets/BlogContent.css";
import Links from "../../../../../data/Links.json"

const BlogEighteenContent = () => {
  const { LejhroBootcampURL } = Links;
  return (
    <div className="blog-content">
      <div className="blog-content-container">
        <h1 className="h2 text-black">
        How are Programming Languages Important to Us ?
        </h1>
        <p className="p">
          Programming languages are rules designed by software engineers to send
          instructions to computers. These programming languages are written in
          lines of code. When it’s arranged in the right order, it can be used
          to create software programs. The software can be anything from a
          mobile application to the interface of a manufacturing machine.
        </p>
        <p className="p-bold">Why Programming is important?</p>

        <p className="p">
          Programming is important to innovate and create eco-friendly solutions
          for global problems. It is important in our daily life to enhance and
          increase the power of computers and the internet.
        </p>
        <p className="p">
          Programming helps in speeding up the input and output processes in a
          machine. It is important to automate, collect, manage, calculate, and
          analyse the processing of data and information accurately. Programming
          helps create software and applications that help computer and mobile
          users in daily life.
        </p>
        <p className="p-bold">
          What are the benefits of learning programming languages?
        </p>
        <p className="p">Become Technical Minded:</p>
        <p className="p">
          By learning a programming language, you’ll also become more
          technically minded which is perfect for a future dominated by
          technology. From troubleshooting your own systems and networks to
          working with IoT home equipment and much more, the technical skills
          you gain as a programmer provide a sound platform for further
          technical experience and qualification.
        </p>
        <p className="p">
          Employment Prospects: It’s clear that IT specialists and programmers
          of pretty much every variety are in demand. Modern programming
          languages like Python and JavaScript but also the RDMS language SQL
          seem to top the lists of the most in-demand programming languages.
        </p>
        <p className="p">
          Job security here is very strong indeed, this is not a fleeting or
          ephemeral trend – programmers will continue in demand, probably
          forever!
        </p>
        <p className="p">
          Automate your Work/Business: From automating your accounting duties
          via spreadsheet automation using some Python and Javascript to
          automating tasks on Slack or Airtable, learning how to code can speed
          up your life.
        </p>
        <p className="p">
          You could also automate your own custom backups (reading/writing file
          automation is pretty simple with Python), send emails and scrape web
          data. Perform instant calculation and analysis on your data and use it
          to interact with APIs.
        </p>
        <p className="p">
          Analyse and Visualise Data: Data visualisation is very useful in many
          industries both inside and outside of information technology.
        </p>
        <p className="p">
          By learning a programming language, you can make sense of data using
          data analysis platforms and tools. SQL, R, and Python are the big 3
          here.
        </p>

        <p className="p-bold">
          {" "}Some of the most popular programming languages today-{" "}
        </p>

        <ul className="p">
          <li>
            <p className="p">
              <b>Python</b>
            </p>

            <p className="p">
              Python is one of the most common programming languages used by
              automation engineers. This high-level, object-oriented programming
              language is scalable and can be used in small and large-scale
              automation projects. It is a preferred programming language in the
              automation industry. Python is an ideal option for automation
              because it is a server-side, scripting language. This makes it an
              ideal language for selenium automation testing
            </p>
          </li>
          <li>
            <p className="p">
              <b>C#</b>
            </p>

            <p className="p">
              C# is a popular automation programming language in the C family.
              It is a general-purpose, multi-paradigm programming language with
              powerful features that make it a prime solution in automation. The
              object and component-oriented programming language is used for
              automation testing on Visual Studio.
            </p>
          </li>

          <li>
            <p className="p">
              <b>JavaScript</b>
            </p>

            <p className="p">
              JavaScript is a general-purpose programming language typically
              used for website and web application development. It also happens
              to be an ideal tool for automation, thanks to the JavaScript for
              Automation (JXA), an interface for automation. AppleScript, the
              programming language designed by Apple, was created on JXA.
            </p>
          </li>
          <li>
            <p className="p">
              <b>PHP</b>
            </p>

            <p className="p">
              PHP is another popular open-source programming language that is
              commonly used for server-side development. The scripting language
              has similar capabilities as Python and it can be used for
              automation. Like the other preferred programming languages for
              automation testing, PHP can be used on the Selenium framework.
            </p>
          </li>
          <li>
            <p className="p">
              <b>R</b>
            </p>

            <p className="p">
              R is a programming language designed by statisticians and
              scientists to make their work easier. It comes with most of the
              standard functions used in data analysis, and many of the most
              useful statistical algorithms are already implemented as freely
              distributed libraries. It's got most of what data scientists need
              to do in data-driven science.
            </p>
            <p className="p">
              Although it's a programming language for creating arbitrary
              functions, many developers use R inside an IDE as a high-powered
              scratchpad for playing with data. This makes it a sort of a hybrid
              between a spreadsheet and a graphic calculator.
            </p>
          </li>
        </ul>
        <p className="p">
          Build up your career by learning programming languages with the help
          of the Data Analysis & Data Science boot camp by Lejhro. It not only
          gives you an amazing learning experience but also provides students
          with a 3-month internship after the Bootcamp by industry experts.
        </p>

        <p className="p">
          So{" "}
          <a
            href = {LejhroBootcampURL}
            target="_blank"
            rel="noreferrer"
          >
            enrol
          </a>{" "}
          yourself in the program and kickstart your career!
        </p>
      </div>
    </div>
  );
};

export default BlogEighteenContent;
/*
<p className='p-bold'></p>
      <p className='p'></p>
*/
