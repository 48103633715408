import React from "react";
import "../../../stylesheets/BlogContent.css";
import Links from "../../../../../data/Links.json";

const BlogElevenContent = () => {
  const { LejhroBootcampURL } = Links;
  return (
    <div className="blog-content">
      <div className="blog-content-container">
        <h1 className="h2 text-black">Career in Data Science</h1>
        <p className="p">
          Data science is the study of data to extract meaningful insights for
          business. It is a multidisciplinary approach that combines principles
          and practices from the fields of mathematics, statistics, artificial
          intelligence, and computer engineering to analyse large amounts of
          data.
        </p>
        <p className="p-bold">Who is a Data Scientist?</p>
        <p className="p">
          Data science is a vast field that employs individuals in a variety of
          roles and responsibilities. The phrase "Data scientist” tends to be
          used as an umbrella term for just about every job in the field. They
          may hold the title of Data Analyst, Business Analyst, Software
          Developer or Marketing Data Scientist—to name a few. Because data has
          become so prevalent in our everyday lives, it might surprise you to
          learn which industries are actively searching for and hiring data
          science professionals.
        </p>
        <p className="p">
          Data scientists are in high demand across a number of sectors, as
          businesses require people with the right combination of technical,
          analytical and communication skills.
        </p>
        <p className="p-bold">Different Roles In Data science:-</p>
        <p className="p">
          <b>Data Analyst</b>
        </p>
        <p className="p">
          As a typical entry-level position, a Data Analyst’s primary job is to
          develop systems that collect and sift through company data, and then
          use it to extract insights that answer business questions with
          actionable solutions.
        </p>
        <p className="p">
          Data analysts are responsible for different tasks such as visualising,
          transforming and manipulating the data. Sometimes they are also
          responsible for web analytics tracking and A/B testing analysis.
        </p>
        <p className="p">
          <b>Data Engineer</b>
        </p>
        <p className="p">
          Data engineers are responsible for designing, building, and
          maintaining data pipelines. They need to test ecosystems for the
          businesses and prepare them for data scientists to run their
          algorithms. They need to keep the ecosystem and the pipeline optimized
          and efficient and ensure that the data is available for data
          scientists and analysts to use.
        </p>
        <p className="p">
          <b>Data Architect</b>
        </p>
        <p className="p">
          Data architect has some common responsibilities with data engineers.
          They both need to ensure that the data is well-formatted and
          accessible for data scientists and analysts and improve the data
          pipelines' performance.
        </p>
        <p className="p">
          In addition to that, data architects need to design and create new
          database systems that match the requirements of a specific business
          model and job requirements. They need to maintain these database
          systems, both from the functionality perspective and the
          administrative one.
        </p>
        <p className="p">
          <b>Marketing Data Scientist</b>
        </p>
        <p className="p">
          When a company builds a new campaign, it’s up to the Marketing Data
          Scientist to analyse company data and user research to inform the
          marketing strategy around the launch and measure its outcomes. On a
          granular level, this could involve anything from email marketing and
          search engine optimization (SEO) to web analytics and growth
          hacking—and everything in between!
        </p>
        <p className="p">
          <b>Machine Learning Engineer</b>
        </p>
        <p className="p">
          Machine learning engineers are very on-demand today. They need to be
          very familiar with the various machine learning algorithms like
          clustering, categorisation, and classification and are up-to-date with
          the latest research advances in the field.
        </p>

        <p className="p">
          Build up your career and get any of these job roles with the help of
          the Data Science boot camp by Lejhro. It not only gives you an amazing
          learning experience but also provides students with a 3-month
          internship after the Bootcamp by industry experts.
        </p>
        <p className="p">
          So{" "}
          <a href={LejhroBootcampURL} target="_blank" rel="noreferrer">
            enroll
          </a>{" "}
          yourself in the program and kickstart your career in the field of data
          science.
        </p>
      </div>
    </div>
  );
};

export default BlogElevenContent;
/*
<p className='p-bold'></p>
      <p className='p'></p>
*/
