import React from "react";
import "../stylesheets/HomepageMain.css";
import TopHeader from "../../../common/components/TopHeader";

function FinancialPageHomeMain() {
  return (
    <div className="home-page-home">
      <TopHeader />
      <h1 className="home-page-name h2-heading">Financial Services</h1>
    </div>
  );
}

export default FinancialPageHomeMain;
