
import React from "react";
import BlogTwentyContent from "./components/BlogTwentyContent";
import TopFixedHeader from '../../../../common/components/TopFixedHeader';
import BlogPageHome from '../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogTwentySix() {
  return (
    <>
      <Helmet>
        <title>Revolutionizing Healthcare: The Power of Data Science | Lejhro
        </title>
        <meta name="description" content="Explore the impact of data science on the healthcare industry. Learn how it revolutionizes patient care and improves outcomes." />
        <meta name="keywords" content="Healthcare, Data Science, Decision Making, Analysis, Machine Learning" />
      </Helmet>
      <TopFixedHeader />
      <BlogPageHome />
      <BlogTwentyContent />
    </>
  );
}

export default BlogTwentySix;



        // {
        //     "title" :"Revolutionizing Healthcare: The Power of Data Science ",
        //     "content":"" Explore the impact of data science on the healthcare industry. Learn how it revolutionizes patient care and improves outcomes.,


        //   "link":"/blogs/revolutionizing-healthcare-the-power-of-data-science"
        // },
