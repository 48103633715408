import React from "react";
import "../Stylesheet/LetsTalk.css";

function Screen() {

  return (
<>
    <div className={`LetsTalk-div`} id="page6">
      <div className="LetsTalk-content">
        <h2 className="h2-heading">Let's Talk</h2>
        <div
          className="LetsTalk-button"
          onClick={() => {
            window.location.pathname = "/contact-us"
          }}
        >
          Contact Us
        </div>
      </div>
    </div>
    </>

  );
}

export default Screen;
