
import React from "react";
import BlogTwentyContent from "./components/BlogTwentyContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogTwenty() {
  return (
    <>
      <Helmet>
        <title>Content Marketing Metrics | Lejhro
        </title>
        <meta name="description" content="Learn how to balance organic and paid results in your content marketing metrics. Discover the key metrics to measure success." />
        <meta name="keywords" content="Content marketing metrics"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogTwentyContent/>
    </>
  );
}

export default BlogTwenty;