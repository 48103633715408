import React from "react";
import BlogSixContent from "./components/BlogSixContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogSix() {
  return (
    <>
      <Helmet>
        <title>Note Down the Importance of a Strong Employer Brand | Lejhro</title>
        <meta name="description" content="Recruiting through employer branding is one of the ways to ensure that you hire the best fit for your organization which is a prerequisite for the organization." />
        <meta name="keywords" content="Hiring, Recruiting, Employer Branding, Hiring with Lejhro"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogSixContent/>
    </>
  );
}

export default BlogSix;
