import React from "react";
import { Link } from "react-scroll";
import "../Stylesheet/SideNavigationBar.css";
import { useState } from "react";

const SideNavigationBar = () => {
  const [isNavBarBlack, setNavBarBlack] = useState(false);
  const [isNavBarHalf, setNavBarHalf] = useState(false);

  const changeClass = () => {
    const height = window.innerHeight;
    if (window.scrollY >= (3.6 * height) - 20) {
      setNavBarBlack(true);
    } 
    else {
      setNavBarBlack(false);
    }
    if (window.scrollY >= 4.85*height){
      setNavBarHalf(true);
    }
    else{
      setNavBarHalf(false)
    }
  };

  window.addEventListener("scroll", changeClass);

  return (
    <div
      className={
        isNavBarBlack
          ? "side-navigation-bar black-navigation"
          : "side-navigation-bar "
      } style={isNavBarHalf ? {transform:"translateY(-10%)"} : {}}
    >
      <Link
        to="page1"
        className="side-navigation-links"
        activeClass="side-navigation-links-active"
        spy={true}
        smooth={true}
        offset={0}
        duration={150}
      >
        <p className="side-navigation-bar-link-text">
          Driving Innovation <br /> Through Change
        </p>
      </Link>
      <Link
        to="page2"
        className="side-navigation-links"
        activeClass="side-navigation-links-active"
        spy={true}
        smooth={true}
        offset={0}
        duration={150}
      >
        <p className="side-navigation-bar-link-text">Empowering <br />The Zoomers</p>
      </Link>
      <Link
        to="page3"
        className="side-navigation-links"
        activeClass="side-navigation-links-active"
        spy={true}
        smooth={true}
        offset={0}
        duration={150}
      >
        <p className="side-navigation-bar-link-text">
          Accelerate <br /> Your Business
        </p>
      </Link>
      <Link
        to="page4"
        className="side-navigation-links"
        activeClass="side-navigation-links-active link-text-visible"
        spy={true}
        smooth={true}
        offset={0}
        duration={150}
      >
        <p className="side-navigation-bar-link-text">
          Build Your <br />Future Workspace
        </p>
      </Link>
      <Link
        to="page5"
        className="side-navigation-links"
        activeClass="side-navigation-links-active link-text-visible"
        spy={true}
        smooth={true}
        offset={0}
        duration={150}
      >
        <p className="side-navigation-bar-link-text">Our Purpose</p>
      </Link>
      <Link
        to="page6"
        className="side-navigation-links"
        activeClass="side-navigation-links-active link-text-visible"
        spy={true}
        smooth={true}
        offset={0}
        duration={150}
      >
        <p className="side-navigation-bar-link-text">Let's Talk</p>
      </Link>
    </div>
  );
};

export default SideNavigationBar;


