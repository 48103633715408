import React from "react";
import "../stylesheets/BlogPageHome.css";
import TopHeader from "../../../common/components/TopHeader";

function BlogPageHomeMain() {
  return (
    <div className="home-page-home">
      <TopHeader />
      <h1 className="home-page-name h2-heading">Blogs</h1>
    </div>
  );
}

export default BlogPageHomeMain;
