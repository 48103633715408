import React from "react";
import "../stylesheets/ContactDetailsPage.css";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import Links from "../../../data/Links.json";
import ContactForm from "./ContactForm";
const ContactDetailsPage = () => {
  const {
    LejhroContactNumber,
    LejhroContactMail,
    LejhroAddressLine1,
    LejhroAddressLine2,
    LejhroAddressLine3
  } = Links;
  return (
    <div className="contact-details-page">
      <div className="contact-hubsopt">
        <ContactForm />
      </div>
      <div className="contact-details">
        <div>
          <div className="contact-details-inside-container">
            <CallIcon />
            <p>
              {LejhroContactNumber}
            </p>
          </div>
          <div className="contact-details-inside-container">
            <EmailIcon />
            <p>
              {LejhroContactMail}
            </p>
          </div>
        </div>
        <div>
          <h4>Mailing Address</h4>
          <p>
            {LejhroAddressLine1}
          </p>
          <p>
            {LejhroAddressLine2}
          </p>
          <p>
            {LejhroAddressLine3}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactDetailsPage;
