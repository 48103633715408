import React from "react";
import "../stylesheets/ServicesDigitalMarketing.css";

function ServicesDigitalMarketing() {
  return (
    <div className="services-digital-marketing-content">
      <h2 className="h2 white-heading h2-1">Digital Marketing</h2>
      <div className="services-digital-marketing-content-left">
        <img src="images/services/services-digital-marketing.jpg" alt="" />
      </div>
      <div className="services-digital-marketing-content-right">
        <h2 className="h2 white-heading h2-2">Digital Marketing</h2>
        <p className="p text-black justify-text">
          In today's cut-throat competitive environment, enterprises in order to
          find their footing, must market themselves digitally and develop a
          strong online presence.
        </p>
        <p className="p text-black justify-text">
          We at Lejhro help businesses in strong relationship building with
          their clients by delivering customized and superior results.
        </p>
      </div>
    </div>
  );
}

export default ServicesDigitalMarketing;
