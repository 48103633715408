
import React from "react";
import "../../../stylesheets/BlogContent.css";
import Links from "../../../../../data/Links.json";

const BlogTwentyContent = () => {
  const { LejhroDataBootcampURL } = Links;
  return (
    <div className="blog-content">
      <div className="blog-content-container">
        <h1 className="h2 text-black">Ethics In Data Science
        </h1>
        <p className="p">
          Data influences businesses through decision-making. However, this valuable resource is not without its drawbacks. Data is personal information, and there must be a proper way to use the data and maintain privacy. 'Ethos' is the Greek word for ethics which means 'habit' or 'customs' and instructs us on right or wrong. Society has rules and regulations. Ethics deals with feelings, laws, and social norms to determine right from wrong
        </p>
        <p className="p-bold">
          Why is it important?
        </p>

        <p className="p">
          Data science is significant in businesses operating in disciplines as diverse as medical sciences, smart cities, and transportation. The need for a focus on data science ethics extends beyond a balance sheet of these potential problems because data science practices challenge our understanding of what it means to be human. Algorithms, when implemented correctly, offer enormous benefits such as cost savings, scalability, speed, accuracy, and consistency.

        </p>
        <p className="p-bold">
          Pros of the Digital World
        </p>

        <p className="p">
          We live in a digital world. Our day-to-day life is dependent on applications run by tech companies. We need to take a taxi we call Uber. We need to order food using Zomato, and so on. These companies have all the data from our email ID, phone numbers, and address to purchase history. Personal data protection is thus an aspect of the present day.

        </p>
        <p className="p">
          In India, the Personal Data Protection Bill affirms the rights of digital citizens and addresses the hazards of commercial exploitation of personal and personally identifiable data. The Data Protection Bill is a long-awaited and desperately needed legislation. The bill will replace antiquated, obsolete, and inadequate data protection policies. It has the potential to raise user understanding of their privacy and hold data custodians and processors accountable.

        </p>

        <p className="p-bold">
          What is Regulation and Owning Data?
        </p>
        <p className="p">
          Legal frameworks attempt to rebalance the inequitable relationships of power and influence between organisations and individuals. The divisions between public and private, individuals and society, and the resource-wealthy and resource-poor are redefining data as the new currency of the international economy. The GDPR establishes the general obligations of data controllers and those processing personal data on their behalf (processors). These include implementing appropriate security measures according to the risk involved in the data processing operations they perform.
        </p>

        <p className="p-bold">
          Principles of Data Protection
        </p>
        <ul>
          <li><p className="p">
            Lawfulness in data protection is essential as it has a legal basis, transparency, and acting in the person's best interest.

          </p></li>
          <li><p className="p">
            Purpose limitations signify processing personal data for the intended purpose or reason.
          </p></li>
          <li><p className="p">
            Storage limitations suggest not storing personal data if it is not needed anymore.
          </p></li>
          <li><p className="p">
            Accountability means that the data processor is responsible for complying with the GDPR.
          </p></li>
          <li><p className="p">
            Accuracy considers reasonable measures to have the most accurate data possible.

          </p></li>
          <li><p className="p">
            Confidentiality indicates that the people responsible for data processing should have access to it.

          </p></li>
          <li><p className="p">
            Data minimization gathers and stores the exact amount of required data.


          </p></li>

        </ul>

        <p className="p">
          Storage technologies protect data by using disk, tape, or cloud backup to safely store copies of the data in data loss or interruption. Additional software tools such as cloning, mirroring, replication, snapshots, and changed block tracking have another layer of data protection with traditional backup. Technology advancements mean that it is now a common practice to provide continuous data protection backing up the data so that recovery can be near-instantaneous.


        </p>

        <p className="p-bold">
          Data Science Ethics

        </p>

        <p className="p">
          Analysts, data scientists, and information technology professionals must be concerned about data science ethics. Anyone dealing with any data type must report any instances of data theft, unethical data collection, storage, use, etc.
          Protected data has been made public on the internet, harming persons whose information has been made available. Misconfigured databases, spyware, theft, or publishing on a public forum can all lead to data leaks. Individuals and organizations must use safe computing practices, conduct frequent system audits, and adopt policies to address computer and data security. Cybersecurity is more crucial for banks and financial institutions which deal with customers' money.

        </p>

        <p className="p-bold">
          Some Ethical Practices

        </p>
        <p className="p-bold">
          Making Decisions

        </p>
        <p className="p">
          Data scientists should never make judgments without contacting a client, even if the decision is in the interest of the project. Data scientists should only make decisions when stated in the contract or when their authority allows them to do so.

        </p>
        <p className="p-bold">
          Privacy and Confidentiality of Data
        </p>

        <p className="p">
          Data concerning client affiliates, customers, workers, or other parties with whom the clients have a confidentiality agreement comes under this category. It is the responsibility of the Data Scientist to protect sensitive information. Even if a consumer consents to your organization collecting, storing, and analyzing their personally identifiable information (PII) such as PAN Card or Aadhar Card, Phone Number, or address details, that does mean they want it made public. Dual-authentication password protection and file encryption are two data security solutions that safeguard privacy.

        </p>
        <p className="p-bold">
          Data Ownership
        </p>

        <p className="p">
          Data Scientists have data ownership. Collecting data without an agreement is illegal and immoral. So, consent is required to acquire data. Signed written agreements, digital privacy policies that require users to accept the terms and conditions of the company, and pop-ups with checkboxes that allow websites to track online behavior using cookies are all typical approaches to getting consent.
        </p>
        <p className="p-bold">

          Good Intention with Data

        </p>

        <p className="p">
          The intentions of data collection and analyzing data must be good. The data professionals must be clear about data usage. Data collection about the spending habits to make an app to manage expenses, then the intention is good.

        </p>

        <p className="p-bold">
          Transparency
        </p>
        <p className="p">
          Data subjects have a right to know how you plan to acquire, keep, and utilize their personal information. Transparency is crucial in data acquisition. Users should be aware of the data storage policy access to choose whether or not to accept site cookies.
        </p>
        <p className="p-bold">
          A Real-Life Case

        </p>
        <p className="p-bold">
          OK Cupid Data Release:
        </p>
        <p className="p">
          In 2016, Emil Kirkegaard and Julius Daugbjerg Bjerrekr of Denmark shared a dataset on the Open Science Framework that included information on over 70,000 members of the online dating service OkCupid. The researchers scraped information from OkCupid. User names (but not real names), ages, gender, religion, and personality are removed from the app by the researchers. The site asks new members to help discover prospective matches to construct their dataset.
          The information gathered between November 2014 and March 2015 is not anonymous but rather personal. The sole reason for not sharing the images by researchers is that it would take up too much hard disc space, according to the researchers.

        </p>

        <p className="p-bold">
          Conclusion
        </p>
        <p className="p">
          Organisations and companies using data and implementing data science must follow a set of ethics while dealing with data. Data may help you make better decisions by making a difference when following ethics and regulations strictly.
        </p>
        <p className="p">
          If you are a data enthusiast who wants to learn more about the significance of data and the field-related aspects and if you want to become a highly demanded Data Scientist, enroll for the in-trend data science boot camp with most opportunities to talk with experts. Sign up now!
        </p>
        <p className="p">
          <a
            href={LejhroDataBootcampURL}
            target="_blank"
            rel="noreferrer"
          >
            www.bootcamp.lejhro.com/data-science-bootcamp
          </a>
        </p>
      </div>
    </div>
  );
};

export default BlogTwentyContent;