import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Steps } from "antd";

const HowToApply = () => {
  return (
    <Container>
      <div className="container">
        <h1
          style={{
            padding: 10,
            display: "flex",
            margin: "auto",
            justifyContent: "center",
            fontSize:'34px',
            marginTop:'3rem',
            color:"#3A3632 "
          }}
        >
          How to Apply?
        </h1>
        <h3
          style={{
            padding: 10,
            display: "flex",
            margin: "auto",
            justifyContent: "center",
            fontSize:'18px',
            color:"#3A3632 "
          }}
        >
          Applying for Lejhro Fixed Deposit online is easy. Book your Lejhro
          Fixed Deposit in four easy steps.
        </h3>
        <Grid container spacing={4} style={{ padding: 15 }}>
          <Grid item sm={12} md={6}>
            <img
              src="./images/steps.png"
              style={{ width: "85%" }}
              alt=""
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Steps
              progressDot
              current={4}
              direction="vertical"
              items={[
                {
                  title: " Step 01",
                  description: "Register using your mobile number",
                },
                {
                  title: " Step 02",
                  description: "Enter PAN details and investment amount",
                },
                {
                  title: " Step 03",
                  description:
                    "Enter tenure, KYC & bank details and complete your payment",
                },
                {
                  title: " Step 04",
                  description:
                    "Receive fixed deposit receipt and maximise your returns",
                },
              ]}
              style={{
                fontWeight: 800,
                color: "black",
                padding: 15,
                margin: 10,
                fontSize: 26,
              }}
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default HowToApply;
