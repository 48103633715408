import React from "react";
import "../Stylesheet/BuildYourFutureWorkspace.css";
import Links from "../../../data/Links.json";

function Screen() {
  const { LejhroRecruitURL } = Links;

  const openInNewTab = url => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
      <div className={`BuildYourFutureWorkspace-div`} id="page4">
        <div className="BuildYourFutureWorkspace-content">
          <div className="BuildYourFutureWorkspace-inside-div">
            <h2 className="homepage-heading">Build Your Future Workforce</h2>
            <h3 className="h4-subheading">
              Reshape the modern workplace by expanding the talent pool
              optimally
            </h3>
          </div>
          <div
            className="BuildYourFutureWorkspace-button"
            onClick={() => {
              openInNewTab(LejhroRecruitURL);
            }}
          >
            Explore
          </div>
        </div>
        <div className="BuildYourFutureWorkspace-img">
          <img src={`images/lejhro-home-page4.png`} alt="" />
        </div>
        <div className="BuildYourFutureWorkspace-img-mobile">
          <img src={`images/lejhro-home-page4-mobile.png`} alt="" />
        </div>
      </div>
  );
}

export default Screen;
