import React from "react";
import BlogFourteenContent from "./components/BlogFourteenContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogFourteen() {
  return (
    <>
      <Helmet>
        <title>Data Science Vs Data Analytics | Lejhro</title>
        <meta name="description" content="There is a constant tussle as to which one is superior or better from a career point of view. Learn the difference between them and make the decision yourself." />
        <meta name="keywords" content="Data Literacy, Data Science Techniques, Data Analytic tools"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogFourteenContent/>
    </>
  );
}

export default BlogFourteen;
