import React from "react";
import BlogFourContent from "./components/BlogFourContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogFour() {
  return (
    <>
      <Helmet>
        <title>How To Take a Career Break Without It Hurting Your Career | Lejhro</title>
        <meta name="description" content="It's necessary to take a sabbatical from your work to rejuvenate yourself. Learn more about the importance to take a break without hurting your career graph." />
        <meta name="keywords" content="Enhancing relationships ,work stress, medical reasons, further studies, mental stress, salary degradation"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogFourContent/>
    </>
  );
}

export default BlogFour;
