import React from "react";
import "../../../stylesheets/BlogContent.css";
import Links from "../../../../../data/Links.json";

const BlogThirteenContent = () => {
  const { LejhroBootcampURL } = Links;
  return (
    <div className="blog-content">
      <div className="blog-content-container">
        <h1 className="h2 text-black">Data Analytics Tools</h1>
        <p className="p-bold">Tools you must learn as a Data Analyst</p>
        <p className="p">
          As quoted by B.B. King-“The beautiful thing about learning is that
          nobody can take it away from you.”
        </p>
        <p className="p">
          A Data Analyst requires many tools to gather insights from data.The
          best tools to learn as a data analyst depend partly on the role you
          are in and the needs of the business. In this article we will talk
          about the some of the most important tools that every budding Data
          Analyst to a skilled professional must learn -
        </p>
        <p className="p-bold">Tableau</p>
        <p className="p">
          Tableau is a market-leading Business Intelligence tool used to analyze
          and visualize data in an easy format. Being named as a leader in the
          Gartner Magic Quadrant 2020 For the eighth consecutive year, Tableau
          allows you to work on live data-set and spend more time on Data
          Analysis rather than Data Wrangling.
        </p>
        <p className="p">
          Tableau is coming up with frequent updates to provide users with the
          following:
        </p>
        <ul className="p">
          <li>Fast Analytics</li>
          <li>Smart Dashboards</li>
          <li>Update Automatically</li>
          <li>Ease of Use</li>
          <li>Explore any data</li>
          <li>
            Publish a dashboard and share it live on the web and on mobile
            devices.
          </li>
        </ul>
        <p className="p-bold">Microsoft Excel</p>
        <p className="p">
          Microsoft Excel is a platform that will help you get better insights
          into your data. Being one of the most popular tools for Data
          Analytics, Microsoft Excel provides the users with features such as
          sharing workbooks, working on the latest version for real-time
          collaboration, and adding data to Excel directly from a photo and so
          on.
        </p>
        <p className="p">
          The recent advancements vary on the basis of the platform. Few of the
          recent advancements in Windows platform are as follows:
        </p>
        <ul className="p">
          <li>
            You can get a snapshot of your workbook with Workbook Statistics
          </li>
          <li>
            You can give your documents more flair with backgrounds and
            high-quality stock images absolutely for free
          </li>
        </ul>

        <p className="p-bold">Programming Languages: R & Python</p>
        <p className="p">
          R and Python are the top programming languages used in the Data
          Analytics field. R is an open-source tool used for Statistics and
          Analytics whereas Python is a high level, an interpreted language that
          has an easy syntax and dynamic semantics.
        </p>

        <p className="p">
          Python is especially good for creating graphics and visual
          representations of numerical information. It lets you visualise data
          collected by presenting it in charts or graphs, and it can also be
          used to interpret large datasets. Python is also a useful tool if you
          need to test a hypothesis through A/B testing and to evaluate the data
          collected.
        </p>

        <p className="p">
          R is broadly used by statisticians for statistical modelling, as it
          offers a great range of statistical and graphical possibilities, and
          it is commonly used to perform data wrangling.
        </p>

        <p className="p-bold">SQL</p>

        <p className="p">
          Many large companies use SQL for data analysis, and it is considered
          to be one of the key tools for analysts. It is one of the most
          prominent tIt is a straightforward tool to learn and can be used for
          complex, challenging data analytics. It is a popular choice among data
          analysts, as the code itself is not difficult to read or understand
          and can be used to manipulate and change data. Additionally, it lets
          you collate data in a similar manner to Excel but over large datasets
          and across multiple tables at once.tools you must learn as a data
          analyst.{" "}
        </p>
        <p className="p">
          Get started with the fundamentals of data analytics and discover the
          tools you must learn as a data analyst with{" "}
          <a href={LejhroBootcampURL} target="_blank" rel="noreferrer">
            Lejhro’s Bootcamp
          </a>{" "}
          on Data Analysis. This bootcamp will introduce you to topics such as
          data wrangling, creating a new data set and APIs, and provide you with
          intermediate skills for data analytics tools such as SQL and Python
          skills. Hurry up and get yourself registered !!
        </p>
      </div>
    </div>
  );
};

export default BlogThirteenContent;
/*
<p className='p-bold'></p>
      <p className='p'></p>
*/
