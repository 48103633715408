import React from "react";
import "../stylesheets/ServicesApplicationModernization.css";
function ServicesApplicationModernization() {
  return (
    <div className="services-application-modern-content">
      <h2 className="h2 white-heading h2-1">Application Modernization</h2>
      <div className="services-application-modern-content-left">
        <img
          src="images/services/services-application-modernization-img.jpg"
          alt=""
        />
      </div>
      <div className="services-application-modern-content-right">
        <h2 className="h2 white-heading h2-2">Application Modernization</h2>
        <p className="p text-black justify-text">
          With the dominance of technology all around the globe and applications
          becoming the new trend, application modernization has become more than
          necessary.
        </p>
        <p className="p text-black justify-text">
          Organizations need to revamp applications in innovative ways in order
          to ensure better growth and survival in the future.
        </p>
      </div>
    </div>
  );
}

export default ServicesApplicationModernization;
