import React from "react";
import BlogTwelveContent from "./components/BlogTwelveContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogTwelve() {
  return (
    <>
      <Helmet>
        <title>Data Analysis: Everything You Need to Know | Lejhro</title>
        <meta name="description" content="Grasp Lejhro’s Introduction to Data Analysis to upskill your knowledge of different pools of Datasets and a briefing on python libraries, programming and more." />
        <meta name="keywords" content="Data analysis, Data cleaning, Data visualisation, Analysis types"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogTwelveContent/>
    </>
  );
}

export default BlogTwelve;
