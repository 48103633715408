import React from "react";
import "../Stylesheet/EmpoweringTheZoomers.css";
import Links from "../../../data/Links.json";

function EmpoweringTheZoomers() {
  const { LejhroBootcampURL } = Links;

  const openInNewTab = url => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
      <div className={`EmpoweringTheZoomers-div`} id="page2">
        <div className="EmpoweringTheZoomers-content">
          <div className="EmpoweringTheZoomers-inside-div">
            <h2 className="homepage-heading">Empowering The Zoomers</h2>
            <h3 className="h4-subheading">
              Stay ahead of the curve with the indispensable soft skills
            </h3>
          </div>
          <div
            className="EmpoweringTheZoomers-button"
            onClick={() => {
              openInNewTab(LejhroBootcampURL);
            }}
          >
            Explore
          </div>
        </div>
        <div className="EmpoweringTheZoomers-img">
          <img src={`images/lejhro-home-page2.png`} alt="" />
        </div>
        <div className="EmpoweringTheZoomers-img-mobile">
          <img src={`images/lejhro-home-page2-mobile.png`} alt="" />
        </div>
      </div>
  );
}

export default EmpoweringTheZoomers;
