import React, { useState } from "react";

import { Container } from "@mui/material";
import { Row, Col } from "antd";
// import { Button } from "antd";
import { Button, Modal } from "antd";

import FinanceForm from "../Common/FormalModal";
import "../stylesheets/About.css";
import aboutBack from "../aboutBack.png";

// import "./About.css"; // Import the CSS file for custom styles

export default function About(props) {
    //   const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    //   const handleOk = () => {
    //     setLoading(true);
    //     setTimeout(() => {
    //       setLoading(false);
    //       setOpen(false);
    //     }, 3000);
    //   };

    const handleCancel = () => {
        setOpen(false);
    };

    const [downloadModal, setDownloadModal] = useState(false);
    const handleClick = () => {
        {
            setDownloadModal(!downloadModal);
        }
    };
    const imagePath = process.env.PUBLIC_URL + "/images/finance3.png";

    return (
        <Container>
            <Row className="flex">
                <Col>
                    <div
                        className="about "
                        style={{
                            // paddingLeft: "17px",

                            // paddingTop: "200px",

                            // marginTop:"2px",

                            // paddingBottom: "200px",

                            // backgroundImage: `url(${imagePath})`,

                            // backgroundRepeat: "no-repeat",

                            // backgroundSize: "100% auto",
                        }}
                    >



                        <div className="about-content flex" style={{ paddingBottom: '3rem', paddingTop: '3rem' }} >

                            <h1 style={{
                                fontSize: "34px",
                                fontWeight: "700",
                                color: "#3A3632",
                            }}>
                                Invest | Earn | Grow
                            </h1>


                            <p style={{ fontSize: "18px", color: "#3A3632" }}>
                                Two Incomes, One Future: Make Investments Smarter.<br></br> Earn 1% interest per month.
                            </p>

                            <p style={{ fontSize: "18px", color: "#3A3632" }}>
                                **T&C Apply
                            </p>

                            <p style={{ fontSize: "18px", color: "#3A3632", paddingTop: "3rem", }}>
                                Talk to Lejhro consultant today.
                            </p>


                            {/* <div className="btn">
            <Button
              type="primary"
              htmlType="submit"
              className="btt"
              style={{
                color: "#fdfdfd",
                background: "#d6932c",
                alignItems: "center",
                marginTop: "2rem",
                display: "flex",
                fontWeight: "600",
                fontSize: "large",

                justifyContent: "center",
                padding: "20px 50px",
                letterSpacing: "1.5px",
              }}
              onClick={handleClick}
            >
              Request a call back
            </Button>
          </div> */}

                            <Button
                                className="btt hover-button"
                                onClick={showModal}
                                style={{ fontSize: 20, height: 45, width: 250, }}
                            >
                                Request a call back
                            </Button>
                            <Modal open={open} onCancel={handleCancel} footer={[]}>
                                <h3 className="request_text">Request a Call Back</h3>
                                <FinanceForm

                                    courseName={"financeService"} />
                            </Modal>
                        </div>
                    </div>
                </Col>
                <Col className="bg ">

                    <img src={aboutBack}
                        alt="background image"
                        style={{ height: '450px', width: '700px' }}
                        className="pic "
                    />

                </Col>


            </Row>
        </Container >
    );
}