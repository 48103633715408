import React from "react";
import "../stylesheets/AboutIntro.css";

function AboutIntro() {
  return (
    <div className="about-intro">
      <p className="p text-black justify-text">
        Lejhro's experienced management team drives our company, guides it to
        accomplish its vision, and inspires its employees to ensure clients
        achieve their goals.
      </p>
      <div className="about-intro-content">
        <div className="about-intro-content-inside">
          <img src="Shakti.png" alt="" />
          <h4 className="h2 text-black center-text">Shakti Panigrahi</h4>
          <p className="h3 text-black">CEO</p>
        </div>
        <div className="about-intro-content-inside">
          <img src="Basu.png" alt="" />
          <h4 className="h2 text-black center-text">Bidintha Basumatary</h4>
          <p className="h3 text-black">Vice President</p>
        </div>
      </div>
    </div>
  );
}

export default AboutIntro;
