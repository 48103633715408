import React from "react";
import BlogTenContent from "./components/BlogTenContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogTen() {
  return (
    <>
      <Helmet>
        <title>Advanced Python Concepts that You Must Know | Lejhro</title>
        <meta name="description" content="Knowing advanced python concepts is something you can’t miss out on! We have compiled some of the most useful concepts from exception skills  to Lambda Function." />
        <meta name="keywords" content="Scikit-learn, Keras ,Theano,Scipy,Selenium, Testcomplete, Robot framework"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogTenContent/>
    </>
  );
}

export default BlogTen;
