import React from "react";
import BlogElevenContent from "./components/BlogElevenContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogEleven() {
  return (
    <>
      <Helmet>
        <title>Career in Data Science | Lejhro</title>
        <meta name="description" content="How to build your career in data science from scratch to become one of the best data scientists in the industry. Start Now to transform your career at 2x speed." />
        <meta name="keywords" content="Data Analyst, Data Engineer, Data Architect, Machine Learning Engineer"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogElevenContent/>
    </>
  );
}

export default BlogEleven;
