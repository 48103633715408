import { Facebook, LinkedIn, Twitter, YouTube } from "@mui/icons-material";
import React from "react";
import "../stylesheets/FooterComponent.css";
import Links from "../../data/Links.json";

function FooterComponent() {
  const {LejhroBootcampURL, LejhroRecruitURL, LejhroTwitter, LejhroFacebook, LejhroInstagram, LejhroYoutube } = Links 
  return (
    <div className="footer-main-div" id="lejhro-footer">
      <div className="footer-header" />
      <div className="footer-body">
        <div className="footer-body-container">
          <div className="footer-section">
            <h3>Company</h3>
            <a href="/innovations">Innovations</a>
            <a href="/business-services">Business Services</a>
            <a href="/financial-services">Financial services</a>
            <a
              href={LejhroRecruitURL}
              target="_blank"
              rel="noreferrer"
            >
              Lejhro Recruiter
            </a>
            <a href="/about">About</a>
            
            <a href="/blogs">
              Blogs
            </a>
          </div>
          <div className="footer-section">
            <h3>Programs</h3>
            <a
              href={LejhroBootcampURL}
              target="_blank"
              rel="noreferrer"
            >
              Lejhro Bootcamp
            </a>
          </div>
          <div className="footer-section">
            <h3>Support</h3>
            <a href="/contact-us">Contact</a>
            <a href="/terms-of-use">Terms of Use</a>
            <a href="/privacy-statement">Privacy Statement</a>
          </div>
          <div className="footer-section">
            <h3>Connect with us</h3>
            <div className="footer-section-connect">
              <a
                href={LejhroTwitter}
                target="_blank"
                rel="noreferrer"
                aria-label="Twitter"
              >
                <Twitter style={{ fontSize: "25px" }} />
              </a>
              <a
                href={LejhroFacebook}
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <Facebook style={{ fontSize: "25px" }} />
              </a>
              <a
                href={LejhroInstagram}
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram"
              >
                <LinkedIn style={{ fontSize: "25px" }} />
              </a>
              <a
                href={LejhroYoutube}
                target="_blank"
                rel="noreferrer"
                aria-label="YouTube"
              >
                <YouTube style={{ fontSize: "25px" }} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className="footer-copyright">
        © {new Date().getFullYear()} LEJHRO. All Rights Reserved.
      </p>
    </div>
  );
}

export default FooterComponent;
