import React, { useState } from "react";
import "../stylesheets/BlogPageCards.css";
import BlogsData from "../../../data/BlogsData.json";
import BlogCard from "./BlogCard";
// import { Pagination } from "antd";

const BlogPageCards = () => {
  // const numEachPage = 6; //default size of page
  // const totalBlogs = 19; //total number of blogs available
  // const [minValue, setMinValue] = useState(0);
  // const [maxValue, setMaxValue] = useState(numEachPage);
  const [loadMoreActive, setLoadMoreActive] = useState(true);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(6);
  const { BlogsCardsData } = BlogsData;

  const handleChange = (value) => {
    // if(loadMoreActive){
    //   setMinValue(0);
    //   setMaxValue(6);
    //   setLoadMoreActive(false)
    // }
    // else{
    //   setMinValue(0);
    //   if(maxValue+3>BlogsCardsData.length){
    //     setMaxValue(BlogsCardsData.length);
    //     setLoadMoreActive(true)
    //   }
    //   else
    //     setMaxValue(maxValue+3)
    // }
    setMinValue(0);
    if (maxValue + 3 > BlogsCardsData.length) {
      setMaxValue(BlogsCardsData.length);
      setLoadMoreActive(false);
    } else setMaxValue(maxValue + 3);
  };
  return (
    <div className="home-page-blogs">
      <h2 className="h2 text-black">Latest Blogs</h2>
      <div className="home-page-blogs-cards-div">
        {BlogsCardsData &&
          BlogsCardsData.length > 0 &&
          BlogsCardsData.slice(minValue, maxValue).map((data, idx) => {
            return (
              <BlogCard
                key={idx}
                title={data.title}
                content={data.content}
                link={data.link}
              />
            );
          })}
      </div>
      <div className="blogs-pagination">
        {/* <Pagination
          responsive
          defaultCurrent={1}
          defaultPageSize={numEachPage}
          onChange={handleChange}
          total={totalBlogs}
        /> */}
        <div
          className={
            loadMoreActive ? "blogs-load-more-button" : "load-more-hidden"
          }
          onClick={handleChange}
        >
          Load More
        </div>
      </div>
    </div>
  );
};

export default BlogPageCards;
