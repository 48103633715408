import React from "react";
import { Container } from "react-bootstrap";

function InterestCharges() {
  return (
    <div style={{backgroundColor:"#f2f2f2"}}>
      <Container style={{ paddingBottom:'2rem'}}>
        <h2
          style={{
            display: "flex",
            // margin: "auto",
            justifyContent: "center",
            fontSize: '34px',
            fontWeight: 400,
            paddingTop:'3rem',
            color:"#3A3632 "
          }}
        >
          Interest & Charges
        </h2>
        <h4
          style={{
            display: "flex",
            // margin: "auto",
            justifyContent: "center",
            fontSize: 16,
            textAlign: "center",
            fontWeight: 400,
            color:"#3A3632 ",
            fontSize:'18px',
          }}
        >
          A Fixed Deposit is a safe investment and independent of market
          fluctuations. Check Lejhro Finance Fixed Deposit <br /> interest
          rates and charges.
        </h4>

        <div
          style={{
            backgroundColor: "#d6932c",
            height: 50,
            width: 150,
            padding: 10,
            textAlign: "center",
            justifyContent: "center",
            margin: "auto",
            fontSize: 18,
            fontWeight: 600,
            marginTop:'3rem',
           
          }}
        >
          Interest Rates
        </div>

        <Container
          style={{
            width: "45%",
            display: "flex",
            margin: "auto",
            justifyContent: "center",
            border: "1px solid #d6932c",
            borderRadius: 20,
            marginBottom: 40,
            marginTop: 20,
            padding: 20,
            backgroundColor: "#F2F2F2",
           
          }}
        >
          <h3
            style={{
              padding: 10,
              fontSize: 18,
              textAlign: "center",
              fontWeight: 400,
              
            }}
          >
            Interest rates  <br /> <b>1% per month </b>
          </h3>
        </Container>
      </Container>
    </div>
  );
}

export default InterestCharges;
