import React from "react";
import "../../../stylesheets/BlogContent.css";

const BlogTwoContent = () => {
  return (
    <div className="blog-content">
      <div className="blog-content-container">
      <h1 className="h2 text-black">5 Things That Tell You To Pursue a Different Career</h1>
        <p className="p">
          Finding a satisfying and good career is not that easy. You have to
          think a lot while choosing one and make yourself successful in it. But
          a time comes when it crosses your mind that are you happy with your
          current career? Is there any development in you? Sometimes you don’t
          feel that enthusiasm you had before. You think of adjusting your
          career path.
        </p>
        <p className="p">
          There could be many reasons to change so let’s share any five things
          as to why you think of a change
        </p>
        <ul className="p">
          <li>
            <p className="p-bold mrz-pz">To lose your passion</p>
            <p className="p">
              Firstly when you choose a career path you think that you will do
              your best. But a time comes when you don't feel that passion in
              your work. You try to avoid work at that time, you don't think
              about the deadlines, you can't fulfill the mission given to you
              b.y your company. This is the time when you will realize that a
              change is required in your career so that you can work with
              enthusiasm.
            </p>
          </li>
          <li>
            {" "}
            <p className="p-bold mrz-pz">No growth in personal development</p>
            <p className="p">
              As you know if there is no personal development in oneself, then
              that is of no use. One works to grow, develop their skills and be
              successful. Then you should think about a change where you can
              grow and learn new skills and improve them.
            </p>
          </li>
          <li>
            <p className="p-bold mrz-pz">
              Not given the right salary despite working hard
            </p>
            <p className="p">
              When you work hard and put in so much effort but still you don't
              get much money for it. Yes, it's important to learn but earning is
              also important for yourself and your family. If you are paid well
              you will also like to work hard and be more focused too.
            </p>
          </li>
          <li>
            <p className="p-bold mrz-pz">Putting skills on your dream career</p>
            <p className="p">
              Sometimes it happens that you studied a career in which you don't
              have the proper skills but you have skills for another career. For
              eg:- You are a B.COM graduate and you don't have the proper skills
              for it but you have skills in media and advertisement. Remember
              one thing the road might be hard but always try to choose the
              career in which you have skills. Even if you realize a little bit
              later, still go for your dream career.
            </p>
          </li>
          <li>
            <p className="p-bold mrz-pz">
              The environment of the workplace becomes too toxic
            </p>
            <p className="p">
              Even if you work well, earn well and have the relevant skills,
              sometimes the toxic environment of your workplace can make you
              frustrated and this will make you lose interest. So if you get an
              opportunity to work somewhere else which is of another career but
              you have skills and the environment is nice then what are you
              waiting for just pursue it and work hard.
            </p>
          </li>
        </ul>

        <p className="p">
          I just want to say that if you can relate to all the above things,
          don't waste your time just follow your heart and choose the right
          career which will make you happy, confident, will enhance your skills
          and will give you a good life.
        </p>
      </div>
    </div>
  );
};

export default BlogTwoContent;
/*
<p className='p-bold'></p>
      <p className='p'></p>
*/
