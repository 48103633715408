import React from "react";
import { Helmet } from 'react-helmet-async';
import AboutHome from "./components/AboutHome";
import AboutIntro from "./components/AboutIntro";
import AboutMission from "./components/AboutMission";
import AboutJoinUs from "./components/AboutJoinUs";
import AboutHomePurpose from "./components/AboutHomePurpose";

function AboutUs() {
  return (
    <>
      <Helmet>
        <title>Lejhro | About</title>
        <meta name="description" content="We are a technology company excelling in consulting and outsourcing. Lejhro was conceptualized in 2013 with ultimate aim of providing innovative solutions." />
        <meta name="keywords" content="Lejhro, Consulting, Technology, About Lejhro, Innovation"/>
      </Helmet>
      <AboutHome />
      <AboutHomePurpose/>
      <AboutIntro />
      <AboutMission/>
      <AboutJoinUs/>
    </>
  );
}

export default AboutUs;

