import React from "react";
import "../stylesheets/BlogPageHome.css";
import TopHeader from "../../../common/components/TopHeader";

function BlogPageHome() {
  return (
    <div className="home-page-home">
      <TopHeader />
      <h2 className="home-page-name h2-heading">Blogs</h2>
    </div>
  );
}

export default BlogPageHome;
