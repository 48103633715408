import React from "react";
import BlogPageCards from "./components/BlogPageCards";
import BlogPageHomeMain from "./components/BlogPageHomeMain";
import TopFixedHeader from "./../../common/components/TopFixedHeader";
import { Helmet } from "react-helmet-async";

function BlogPage() {
  return (
    <>
      <Helmet>
        <title>Read Trending Blogs & Articles | Lejhro Bootcamp</title>
        <meta
          name="description"
          content="Explore in-demand fields like digital marketing, data science and many more on our blogs. Get in-depth coverage and stay connected to the latest industry trends"
        />
        <meta name="keywords" content="blogs and articles" />
      </Helmet>
      <TopFixedHeader />
      <BlogPageHomeMain />
      <BlogPageCards />
    </>
  );
}

export default BlogPage;
