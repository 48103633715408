import React from "react";
import "../stylesheet/InnovationProductDevelopmentContent.css";
function InnovationProductDevelopment() {
  return (
    <div className="innovation-product-development-content">
      <h4 className="h2 white-heading ipdc-hidden-heading">
        What is Innovation Product Development?
      </h4>
      <div
        className="innovation-product-development-content-left"
        id="innovation-product-development-content-left"
      >
        <h4 className="h2 white-heading ipdc-heading">
          What is Innovation Product Development?
        </h4>
        <p className="p text-black justify-text">
          It's a cross-functional activity that involves the process of
          transforming innovative ideas into reality. The innovation product
          development methodology consists of stages like ideation, design,
          validation, and launch of the final product.
        </p>
        <p className="p text-black justify-text">
          Additionally, this process also includes regular reviews of customer
          data, their problem statement, and feedback.
        </p>
      </div>
      <div
        className="innovation-product-development-content-right"
        id="innovation-product-development-content-right"
      >
        <img src="/images/innovations-product-development-small.png" alt="" />
      </div>
    </div>
  );
}

export default InnovationProductDevelopment;
