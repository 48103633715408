import React from "react";
import "../stylesheets/ServicesQualityAssurance.css";

function ServicesQualityAssuarance() {
  return (
    <div className="services-quality-assurance-content">
      <h2 className="h2 white-heading h2-1">Quality Assuarance</h2>
      <div
        className="services-quality-assurance-content-left"
        id="services-quality-assurance-content-left"
      >
        <h2 className="h2 white-heading h2-2">Quality Assurance</h2>
        <p className="p text-black justify-text ">
          Now digitally transforming and revamping organizations has become
          quintessential in this era.
        </p>
        <p className="p text-black justify-text">
          Re-structure your enterprises with quality assurance services from
          Lejhro which are mainly focused on building strategic relations to
          magnify our clients' value.
        </p>
      </div>
      <div
        className="services-quality-assurance-content-right"
        id="services-quality-assurance-content-right"
      >
        <img src="images/services/services-qulaty-assurance.jpg" alt="" />
      </div>
    </div>
  );
}

export default ServicesQualityAssuarance;
