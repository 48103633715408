import React from "react";
import BlogSevenContent from "./components/BlogSevenContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogSeven() {
  return (
    <>
      <Helmet>
        <title>Artificial Intelligence | Lejhro</title>
        <meta name="description" content="With tech growing by leaps and bounds, Artificial Intelligence is developing more. Mankind has gone deep into the core of technology to build their clones." />
        <meta name="keywords" content="Tensor Flow, Caffe, Pytorch, Scikit, AutoML, Theano"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogSevenContent/>
    </>
  );
}

export default BlogSeven;
