import React from "react";
import "../stylesheets/AboutHome.css";
import TopHeader from "./../../../common/components/TopHeader";
import TopFixedHeader from "./../../../common/components/TopFixedHeader";
function AboutHome() {
  return (
    <div className="about-home">
      <TopHeader />
      <TopFixedHeader />
      <h1 className="about-name h2-heading">About Us</h1>
    </div>
  );
}

export default AboutHome;
