import React, { useState } from "react";

import axios from "axios";
import { Spin, Button, Form } from "antd";
import Links from "../../../data/Links.json";

const FinanceForm = (props) => {
  const { LejhroHubspotFormPortalID, LejhroHubspotFormGuid } = Links;
  const [input1Value, setInput1Value] = useState(null);
  const [input1Error, setInput1Error] = useState(null);

  const [input3Value, setInput3Value] = useState(null);
  const [input3Error, setInput3Error] = useState(null);
  const [input4Value, setInput4Value] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isForm, setIsForm] = useState(true);
  const [result, setResult] = useState("");

  const submitForm = async () => {
    let hasErrors = false;
    if (!validateEmail(input1Value)) {
      setInput1Error("Please enter a valid Email ID e.g.:abc@abc.com");
      hasErrors = true;
    }

    if (!validateText(input3Value)) {
      setInput3Error("Please check the mobile number you have entered");
      hasErrors = true;
    }

    // console.log(hasErrors);
    if (hasErrors) {
      return null;
    }
    setIsSubmitting(true);
    const portalId = "24964241"; //"24964241";
    const formGuid = "afbfaf07-447d-4581-bc39-7914a20d1218"; //"afbfaf07-447d-4581-bc39-7914a20d1218";
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        {
          portalId,
          formGuid,
          fields: [
            {
              name: "email",
              value: input1Value,
            },
            {
              name: "phone",
              value: input3Value,
            },
            {
              name: "course_name",
              value: props.courseName,
            },
          ],
        },
        config
      );
      if (response.data) {
        setIsSubmitting(false);
        setIsForm(false);
        setResult(
          "Thanks for contacting us. Concern Team will reach out to you."
        );
      }
    } catch (err) {
      console.log(err);
      setIsSubmitting(false);
      setIsForm(false);
      setResult("Some Error Occurred!. Please try again");
    }
  };

  const validateEmail = (values) => {
    if (!values || values === "") {
      return false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values)) {
      return false;
    }
    return true;
  };
  const validateText = (value) => {
    if (!value || value === "") {
      return false;
    }
    return true;
  };
  return (
    <Spin spinning={isSubmitting}>
      {isForm && (
        <Form
          className="contact-form"
          onFinish={submitForm}
            // style={{
            //   padding: 15,
            //   margin: 10,
            //   marginTop: 20,
            //   width: "55%",
            //   border: "1px solid #3a3632",
            //   borderRadius: 10,
            //   boxShadow: "4px 4px 10px 2px #7d7b7a",
            // }}
        >
          <div
            className={
              input1Error === null
                ? "contact-form-field-holder"
                : "contact-form-field-holder-error contact-form-field-holder"
            }
          >
            <input
              type="text"
              id="email"
              onChange={(e) => {
                setInput1Value(e.target.value);
              }}
              onBlur={() => {
                !validateEmail(input1Value)
                  ? setInput1Error(
                      "Please enter a valid Email ID e.g.:abc@abc.com"
                    )
                  : setInput1Error(null);
              }}
              className="p"
            />
            <label
              htmlFor="email"
              className={input1Value ? "contact-form-field-label-text" : ""}
            >
              EMAIL <span>*</span>
            </label>
            <p
              className={
                input1Error === null ? "hidden" : "contact-form-error-p"
              }
            >
              {input1Error}
            </p>
          </div>

          <div
            className={
              input3Error === null
                ? "contact-form-field-holder"
                : "contact-form-field-holder-error contact-form-field-holder"
            }
          >
            <input
              type="tel"
              id="mobilenumber"
              onChange={(e) => {
                setInput3Value(e.target.value);
              }}
              onBlur={() => {
                !validateText(input3Value)
                  ? setInput3Error(
                      "Please check the mobile number you have entered"
                    )
                  : setInput3Error(null);
              }}
              className="p"
            />
            <label
              htmlFor="mobilenumber"
              className={input3Value ? "contact-form-field-label-text" : ""}
            >
              PHONE NUMBER <span>*</span>
            </label>
            <p
              className={
                input3Error === null ? "hidden" : "contact-form-error-p"
              }
            >
              {input3Error}
            </p>
          </div>

          <div className="contact-form-button-holder">
            <Button
              type="primary"
              htmlType="submit"
              style={{ background: "#dd3b30" }}
            >
              SUBMIT
            </Button>
          </div>
        </Form>
      )}
      {!isForm && <p>{result}</p>}
    </Spin>
  );
};

export default FinanceForm;
