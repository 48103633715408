
import React from "react";
import BlogTwentyContent from "./components/BlogTwentyContent";
import TopFixedHeader from '../../../../common/components/TopFixedHeader';
import BlogPageHome from '../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogTwentyTwo() {
  return (
    <>
      <Helmet>
        <title>Build ML Models with Apache Spark | Lejhro
        </title>
        <meta name="description" content="Learn to build a machine learning model using Apache Spark. Follow our step-by-step guide and leverage the power of distributed computing." />
        <meta name="keywords" content="Machine learning, Apache Spark, Model Building, Big Data, Data processing"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogTwentyContent/>
    </>
  );
}

export default BlogTwentyTwo;


        // {
        //     "title" : "Build ML Models with Apache Spark",
        //     "content":"Learn to build a machine learning model using Apache Spark. Follow our step-by-step guide and leverage the power of distributed computing.",
        //     "link" : "/blogs/build-ml-models-with-apache-spark"
        // },