import React from "react";
import "../Stylesheet/OurPurpose.css";

function Screen(props) {

  return (
    <div className={`OurPurpose-div `} id="page5">
      <div className="OurPurpose-content">
        <img src="/logo512.png" alt="" />
        <div className="our-purpose-inside-div">
        <h2 className="h2-heading text-black">Our Purpose</h2>
        <h3 className="h4-subheading text-black">
          To provide innovative solutions through change
        </h3>
        </div>
        <div
          className="OurPurpose-button"
          onClick={() => {
            window.location.pathname = "/about"
          }}
        >
          Read More
        </div>
      </div>
    </div>

  );
}

export default Screen;
