import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";



const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: "2px solid #605656",
    
  },
  "&:before": {
    display: "none",

  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1.5rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    // borderBottom: "3px solid rgba(0, 0, 0, .125)",

  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
   

  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
//   borderBottom: "3px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState({
    panel1: false,
    panel2: false,
    panel3: false,
    // Add more panels if needed
  });
  
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: newExpanded,
    }));
  };
  return (
    <Container>
      <div style={{ justifyContent: "center",marginBottom:"50px",marginTop:'3rem' }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h2 style={{fontSize: "40px"}}>FAQs</h2>
        </div>
        <div style={{ padding: "10px" }}>
          <Accordion
            expanded={expanded.panel1}
            onChange={handleChange("panel1")}
           
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography  sx={{ fontSize: "20px", padding:"20px", }}> Can I add funds to my Fixed Deposit? </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                No, funds cannot be added at any time of the tenure once your FD
                is booked.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded.panel2}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography  sx={{ fontSize: "20px",padding:"20px", }}> How will I receive the interest amount?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The interest on FD will be credited to your registered bank
                account
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded.panel3}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography  sx={{ fontSize: "20px",padding:"20px", }}> What is a maturity date?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                A maturity date is when all funds due are paid into your
                nominated account.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography  sx={{ fontSize: "20px",padding:"20px", }}>
                {" "}
                Are there any special rates for senior citizens?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography  sx={{ fontSize: "20px",padding:"20px", }}>
                Yes, Shriram offers special interest rates for senior citizens
                at an additional 0.50% p.a.* on fixed deposits{" "}
              </Typography>
            </AccordionDetails>
          
          </Accordion> */}
         <span></span>
          {/*  */}

       
        </div>
      </div>
    </Container>
  );
}
