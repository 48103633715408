import React from "react";
import BlogThirtyThreeContent from "./components/BlogThirtyThreeContent";
import TopFixedHeader from '../../../../common/components/TopFixedHeader';
import BlogPageHome from '../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogThirtyThree() {
  return (
    <>
      <Helmet>
        <title> Marketing Automation - A Complete Wrap-Up | Lejhro
        </title>
        <meta name="description" content=" Maximise your marketing impact through automation. Explore tools, techniques, and benefit rates of it in our comprehensive blog on marketing automation.

" />
        <meta name="keywords" content="Marketing automation software, Digital marketing automation, Benefits of marketing automation, Marketing automation system, Marketing automation trends.
"/>
      </Helmet>
      <TopFixedHeader />
      <BlogPageHome />
      <BlogThirtyThreeContent />
    </>
  );
}

export default BlogThirtyThree;



// {
//     "title" : "Marketing Automation - A Complete Wrap-Up",
//     "content":"Marketing automation software, Digital marketing automation, Benefits of marketing automation, Marketing automation system, Marketing automation trends."


        //   "link":"/blogs/marketing-automation-a-complete-wrap-up"
        // },