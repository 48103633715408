import React from "react";
import "../stylesheets/ServicesHomescreen.css";
import TopHeader from "./../../../common/components/TopHeader";
function ServicesHomescreen() {
  return (
    <div className="services-homescreen">
      <TopHeader />
      <div className="services-content-container">
      <div className="services-home-img" id="services-home-img">
        <img src="images/services/services-homepage.png" alt="" />
      </div>
      <div className="services-home-content" id="services-home-content">
        <h1 className="h2-heading">Accelerate Your Business</h1>
      </div>
      </div>
    </div>
  );
}

export default ServicesHomescreen;
