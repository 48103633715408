import React, { useState } from "react";
import "../Stylesheet/TopFloatingHeader.css";
import SideDrawer from "./../../../common/components/SideDrawer";
import { Button } from "antd";

const TopFloatingHeader = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [disableNavBars, setDisableNavBars] = useState(false);
  const [blueLogo, setBlueLogo] = useState(false);

  const changeSetDisableNavBars = () => {
    if (window.scrollY >= 40) {
      setDisableNavBars(true);
    } else {
      setDisableNavBars(false);
    }
    var offsetHeight = document.getElementById('homepage-upper-divs').offsetHeight 
    if(window.scrollY >= offsetHeight - 5){

      setBlueLogo(true)
    }
    else{
      setBlueLogo(false)
    }
  };
  window.addEventListener("scroll", changeSetDisableNavBars);

  return (
    <div className="top-floating-header-header-div">
      <SideDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <div className="top-floating-header-header-logo-div">
        <a href="/">
          <img
            src={blueLogo ? "/lejhro_logo_blue.png" : "/lejhro_logo_white.png"}
            alt="Lejhro Logo"
            className="top-floating-header-fixed-logo"
          />
        </a>
      </div>
      <div className="top-floating-header-links-div">
        <a href="/innovations" className={disableNavBars ? "top-floating-header-hidden-links-div" : ""}>Innovations</a>
        <a href="/business-services" className={disableNavBars ? "top-floating-header-hidden-links-div" : ""}>Business Services</a>
        <a href="/financial-services" className={disableNavBars ? "top-floating-header-hidden-links-div" : ""}>Financial Services</a>
        <a href="http://bootcamp.lejhro.com/" target="_blank" className={disableNavBars ? "top-floating-header-hidden-links-div" : ""}
              rel="noreferrer">Bootcamp</a>
        <Button
        onClick={() => {
          setDrawerOpen(true);
        }}
        className={blueLogo ? "top-floating-header-drawer-button-links top-floating-header-drawer-button-links-black" : "top-floating-header-drawer-button-links"}
        aria-label="Open Drawer"
      >
          <div className="side-drawer-button-container-div-line1"/>
          <div className="side-drawer-button-container-div-line2"/>
          <div className="side-drawer-button-container-div-line1"/>
      </Button>
      </div>
      <Button
        onClick={() => {
          setDrawerOpen(true);
        }}
        className={blueLogo ? "top-floating-header-drawer-button top-floating-header-drawer-button-black" : "top-floating-header-drawer-button"}
        aria-label="Open Drawer"
      >
          <div className="side-drawer-button-container-div-line1"/>
          <div className="side-drawer-button-container-div-line2"/>
          <div className="side-drawer-button-container-div-line1"/>
      </Button>
    </div>
  );
};

export default TopFloatingHeader;

