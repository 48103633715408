import React from "react";
import BlogEighteenContent from "./components/BlogEighteenContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogEighteen() {
  return (
    <>
      <Helmet>
        <title>How are Programming Languages Important to Us? | Lejhro</title>
        <meta name="description" content="With the world undergoing a technology revolution, programming quintessential tools have become the backbone of optimizing the current trends for the better." />
        <meta name="keywords" content="Python, C#, Java Script, PHP, R"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogEighteenContent/>
    </>
  );
}

export default BlogEighteen;
