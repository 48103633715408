import React from "react";
import PrivacyStatementContent from "./components/PrivacyStatementContent";
import PrivacyStatementHome from "./components/PrivacyStatementHome";
import { Helmet } from 'react-helmet-async';

function PrivacyStatement() {
  return (
    <>
    <Helmet>
        <title>Privacy Statement | Lejhro</title>
        <meta name="description" content="This privacy statement has been made by Lejhro to ensure there is no violation of any of the policies. It contains content on the services and training provided." />
        <meta name="keywords" content="Privacy statement, Policy, Procedures, Lejhro"/>
      </Helmet>
      <PrivacyStatementHome/>
      <PrivacyStatementContent/>
    </>
  );
}

export default PrivacyStatement;


