import React from "react";
import BlogThirtyFiveContent from "./components/BlogThirtyFiveContent";
import TopFixedHeader from '../../../../common/components/TopFixedHeader';
import BlogPageHome from '../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogThirtyFive() {
  return (
    <>
      <Helmet>
        <title> Social Media: Boost Biz Growth with Engaging Content. | Lejhro
        </title>
        <meta name="description" content="Social media growth: Set goals, know audience, choose platforms, engage with content, optimize profiles, drive interactions, leverage user content.

" />
        <meta name="keywords" content="Digital Marketing, Social Media, Social Media, Content Strategy, Using Hashtags, Audience Engagement,  Target Audience, Social Media Platforms, Social Media Marketing.
"/>
      </Helmet>
      <TopFixedHeader />
      <BlogPageHome />
      <BlogThirtyFiveContent />
    </>
  );
}

export default BlogThirtyFive;



// {
//     "title" : "Social Media: Boost Biz Growth with Engaging Content.",
//     "content":"Digital Marketing, Social Media, Social Media, Content Strategy, Using Hashtags, Audience Engagement,  Target Audience, Social Media Platforms, Social Media Marketing.",
//     "link":"/blogs/social-media-boost-biz-growth-with-engaging-content"
// },