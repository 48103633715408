import React from "react";
import BlogNineContent from "./components/BlogNineContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogNine() {
  return (
    <>
      <Helmet>
        <title>Internet of Things | Lejhro</title>
        <meta name="description" content="Internet churns and analyze loads and loads of data. Internet of things refers to the collective network of multiple devices to facilitate communication." />
        <meta name="keywords" content="Machine learning, data analysis, artificial intelligence,digital technology"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogNineContent/>
    </>
  );
}

export default BlogNine;
