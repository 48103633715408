import React from 'react'
import { Helmet } from 'react-helmet-async';
import ContactHome from "./components/ContactHome"
import ContactDetailsPage from './components/ContactDetailsPage';

function Contact() {
  return (
    <>
    <Helmet>
        <title>Lejhro | Contact Us</title>
        <meta name="description" content="In order to connect with us, reach out on contact@lejhro.com. Or give a call on this toll free number +919937175522." />
        <meta name="keywords" content="Contact Us, Number, Email Id"/>
      </Helmet>
    <ContactHome/>
    <ContactDetailsPage/>
    </>
  )
}

export default Contact