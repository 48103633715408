
import React from "react";
import BlogThirtyContent from "./components/BlogThirtyContent";
import TopFixedHeader from '../../../../common/components/TopFixedHeader';
import BlogPageHome from '../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogThirty() {
  return (
    <>
      <Helmet>
        <title>Essential Email Marketing Metrics You Need to Know in 2023 | Lejhro.
        </title>
        <meta name="description" content="Learn about Delivery Rate, Email Open Rate, CTR, and more. Monitor your metrics and improve your email marketing campaigns to maximize your success in the field.
" />
        <meta name="keywords" content="Email Campaigns, Delivery Rate, Email Open Rate, CTR, Email Metrics, Bounce Rate, Unsubscribe Rate
"/>
      </Helmet>
      <TopFixedHeader />
      <BlogPageHome />
      <BlogThirtyContent />
    </>
  );
}

export default BlogThirty;



// {
//     "title" : "Essential Email Marketing Metrics You Need to Know in 2023.",
//     "content":"Email Campaigns, Delivery Rate, Email Open Rate, CTR, Email Metrics, Bounce Rate, Unsubscribe Rate."


        //   "link":"/blogs/essential-email-marketing-metrics-you-need-to-know-in-2023"
        // },