import React from "react";
import "../stylesheets/ServicesDataAnalyst.css";

function ServicesDataAnalyst() {
  return (
    <div className="services-data-analyst-content">
      <h2 className="h2 white-heading h2-1">Data Analyst</h2>
      <div
        className="services-data-analyst-content-left"
        id="services-data-analyst-content-left"
      >
        <h2 className="h2 white-heading h2-2">Data Analyst</h2>
        <p className="p text-black justify-text">
          In today's ever changing environment businesses have to ensure a
          flexible and dynamic approach for a brighter future.
        </p>
        <p className="p text-black justify-text">
          In order to flourish enterprises must rethink their working style and
          develop the ability to predict, brainstorm, respond and adapt to any
          setbacks or challenges.
        </p>
      </div>
      <div
        className="services-data-analyst-content-right"
        id="services-data-analyst-content-right"
      >
        <img src="images/services/services-data-analyst-img.jpg" alt="" />
      </div>
    </div>
  );
}

export default ServicesDataAnalyst;
