import React from "react";
import FinancialPageHomeMain from "./Components/FinancialHomePageMain";
import About from "./Components/About";
import Introduction from "./Components/Introduction";
import HowToApply from "./HowToApply";
import InterestCharges from "./InterestCharges";
import CustomizedAccordions from "./FAQ/CustomizedAccordions";
import { Helmet } from 'react-helmet-async';

function FinancialServices() {

    return (
        <>
            <Helmet>
                <title>Lejhro | Financial Services</title>
                <meta name="description" content="We at Lejhro aim to provide services to our clients on different courses and a lot more. Explore more now to avail our exceptional services on preferred fields." />
                <meta name="keywords" content="Services, Lejhro, Financial, Consulting" />
            </Helmet>
            <FinancialPageHomeMain />
            <About />
            <Introduction />
            <HowToApply />
            <InterestCharges />
            <CustomizedAccordions />
        </>
    );
}

export default FinancialServices;
