import React from "react";
import BlogOneContent from "./components/BlogOneContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogOne() {
  return (
    <>
      <Helmet>
        <title>Digital Marketing Vs Traditional Marketing | Lejhro</title>
        <meta name="description" content="Businesses rely on digital marketing era with drastic acceptance. In this blog, we explore the pros and cons of both for you to decide which is better." />
        <meta name="keywords" content="Promoting products, services  and brands through Internet Marketing,Social media marketing, Content writing, Email Marketing,Social media marketing"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogOneContent/>
    </>
  );
}

export default BlogOne;
