import React from "react";
import "../stylesheets/AboutHomePurpose.css";

const AboutHomePurpose = () => {
  return (
    <div className="about-home-purpose">
      <img src="logo192.png" alt="Lejhro Logo" height={192} width={192}/>
      <h2 className="h2-heading text-black">Our Purpose</h2>
      <h4 className="h4-subheading text-black">
        To provide innovative solutions through change
      </h4>
    </div>
  );
};

export default AboutHomePurpose;
