import React from "react";
import "../stylesheets/ServicesCyberSecurity.css";

function ServicesCyberSecurity() {
  return (
    <div className="services-cyber-security-content">
      <h2 className="h2 white-heading h2-1">Cyber Security</h2>
      <div className="services-cyber-security-content-left">
        <img src="images/services/services-cyber-security.jpg" alt="" />
      </div>
      <div className="services-cyber-security-content-right">
        <h2 className="h2 white-heading h2-2">Cyber Security</h2>
        <p className="p text-black justify-text">
          With the advent of the digital era and businesses being heavily
          reliant on technology, cyber security becomes very crucial for an
          organization.
        </p>
        <p className="p text-black justify-text">
          Now, work securely with Lejhro's cyber security services which are
          based on the strategy of securing and ensuring scalability without
          compromising on safety.
        </p>
      </div>
    </div>
  );
}

export default ServicesCyberSecurity;
