import React from "react";
import "../../../stylesheets/BlogContent.css";

const BlogThreeContent = () => {
  return (
    <div className="blog-content">
      <div className="blog-content-container">
      <h2 className="h2 text-black">Things not to put on your CV</h2>
        <p className="p">
          Your CV is a reflection of you. This document will always set your
          first impression on the interviewer. Always remember your CV is the
          first step that leads you to the next step in the selection process so
          make sure that your CV is realistic. Invest your time and energy in
          making a good CV for yourself. But before making a CV you should be
          well familiar with the things that are not supposed to be included in
          your CV. So, here it goes
        </p>

        <p className="p">
          Make sure your resume doesn’t include any of these unnecessary
          elements. Try to use these points to give yourself the best chance of
          being invited for an interview:
        </p>
        <ul className="p">
          <li>Too much information</li>
          <li>Spelling mistakes and grammatical errors</li>
          <li>Inaccuracies about your qualifications or experience</li>
          <li>Unnecessary personal information</li>
        </ul>
        <p className="p-bold">Too much information</p>
        <p className="p">
          Try not to include everything on your resume because this will make
          important information less visible. Note that interviewers may review
          hundreds of resumes in a day, so try to be short and on point, so
          focus on the skills you have that make you a good candidate for the
          role you want. Effectively use bullet points to highlight your key
          abilities. Nowadays recruiters use software that scans resumes for the
          keywords they are looking for and proceeds with their selection based
          on that.
        </p>

        <p className="p-bold">Spelling mistakes and grammatical errors</p>
        <p className="p">
          Before uploading your resume make sure you check that there are no
          spelling or grammatical mistakes. You won't want the interviewer to
          think you are careless and not so serious regarding this. Make sure to
          use correct tense and if you are using the third person make sure you
          are sticking with it throughout your document. You can use spelling
          and grammar checks in your word processor or online.
        </p>

        <p className="p-bold">Inaccuracy about qualification and experience</p>
        <p className="p">
          One of the biggest mistakes anyone could do is lie about your
          qualification or experience. Lying about these things might lead you
          to get an interview but will never result in getting a job as all the
          documents are required to be submitted about the things that you have
          mentioned in an interview.
        </p>

        <p className="p-bold">Unnecessary personal information</p>
        <p className="p">
          Mention your achievements that make you a great employee for any
          company, so talk about that information that/’s most relevant to the
          job. You can share your experience that led you to your current
          career. For example, you can mention your hobbies in your CV but don’t
          start giving details on these things until and unless these hobbies
          are related to your job description. Remember Your CV should have the
          answer to the interviewer's question ‘‘Why should I interview this
          person?’’ and ‘‘What will they bring to the organization?’’
        </p>
      </div>
    </div>
  );
};

export default BlogThreeContent;
/*
<p className='p-bold'></p>
      <p className='p'></p>
*/
