
import React from "react";
import BlogTwentyContent from "./components/BlogTwentyContent";
import TopFixedHeader from '../../../../common/components/TopFixedHeader';
import BlogPageHome from '../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogTwentyFour() {
  return (
    <>
      <Helmet>
        <title>How to do a competitive SEO analysis? : An Overview | Lejhro
        </title>
        <meta name="description" content="Perform a comprehensive competitive SEO analysis with our step-by-step guide. Learn valuable insights and stay ahead of the competition." />
        <meta name="keywords" content="SEO, competitive analysis, Competitive SEO analysis, Analyzing SEO competition, SEO competitive analysis"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogTwentyContent/>
    </>
  );
}

export default BlogTwentyFour;


        // {
        //     "title" : "How to do a competitive SEO analysis?",
        //     "content":" Perform a comprehensive competitive SEO analysis with our step-by-step guide. Learn valuable insights and stay ahead of the competition.",
        //     
        
          //"link":"/blogs/how-to-do-competitive-seo-analysis"
        // },