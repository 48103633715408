import React from "react";
import BlogFifteenContent from "./components/BlogFifteenContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogFifteen() {
  return (
    <>
      <Helmet>
        <title>Data Storytelling |  Lejhro</title>
        <meta name="description" content="Data storytelling is the practice of communicating a piece of information with relevant facts along with helding a close visualization of the data concepts." />
        <meta name="keywords" content="Data Narrations, Business storytelling, Data concepts"/>
      </Helmet> 
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogFifteenContent/>
    </>
  );
}

export default BlogFifteen;
