import React from "react";
import "../stylesheets/AboutJoinUs.css";
import Links from "../../../data/Links.json";
function AboutJoinUs() {
  const { LejhroAboutMail } = Links;
  return (
    <div className="about-join-us">
      <h2 className="text-black h2 about-why-join-heading1">Why Join us?</h2>
      <img src="images/about-join-us.jpg" alt="" />
      <h2 className="text-black h2 about-why-join-heading2">Why Join us?</h2>
      <p className="p text-black justify-text">
        Lejhro was founded with a rebellious spirit and its objective was to be
        the change and make a mark with its technological innovations and
        remarkable products.
      </p>
      <p className="p text-black">
        To apply mail your cv to{" "}
        <a href={LejhroAboutMail}>{LejhroAboutMail.substring(7)}</a>
      </p>
    </div>
  );
}

export default AboutJoinUs;
