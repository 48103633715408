import React from "react";
import BlogThirtyOneContent from "./components/BlogThirtyOneContent";
import TopFixedHeader from "../../../../common/components/TopFixedHeader";
import BlogPageHome from "../../components/BlogPageHome";
import { Helmet } from "react-helmet-async";

function BlogThirtyOne() {
  return (
    <>
      <Helmet>
        <title>Modern Data Analysis Principles: The Laws of Data Science</title>
        <meta
          name="description"
          content="Maximize your success in data analysis principles: Quality data, EDA, Feature Eng., Model Selection, Interpretability, and Ethics. Master them & stay ahead.

"
        />
        <meta
          name="keywords"
          content="Data Science, Data Analytics, Data Quality, EDA, Feature Engineering, Model Selection, Interpretability, Ethics
"
        />
      </Helmet>
      <TopFixedHeader />
      <BlogPageHome />
      <BlogThirtyOneContent />
    </>
  );
}

export default BlogThirtyOne;

// {
//     "title" : "Modern Data Analysis Principles: The Laws of Data Science",
//     "content":" Data Science, Data Analytics, Data Quality, EDA, Feature Engineering, Model Selection, Interpretability, Ethics"

//   "link":"/blogs/modern-data-analysis-principles-the-laws-of-data-science"
// },
