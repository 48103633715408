import React from "react";
import { Helmet } from 'react-helmet-async';
import ServicesHomescreen from "./components/ServicesHomescreen";
import ServicesDataAnalyst from "./components/ServicesDataAnalyst";
import ServicesApplicationModernization from "./components/ServicesApplicationModernization";
import ServicesDevelopmentAndMaintenance from "./components/ServicesDevelopmentAndMaintenance";
import ServicesDigitalMarketing from "./components/ServicesDigitalMarketing";
import ServicesCyberSecurity from "./components/ServicesCyberSecurity";
import ServicesQualityAssuarance from "./components/ServicesQualityAssuarance";
import TopFixedHeader from './../../common/components/TopFixedHeader';

function Services() {
  return (
    <>
      <Helmet>
        <title>Lejhro | Business Services</title>
        <meta name="description" content="We at Lejhro aim to provide services to our clients on different courses and a lot more. Explore more now to avail our exceptional services on preferred fields." />
        <meta name="keywords" content="Services, Lejhro, Technology, Consulting"/>
      </Helmet>
      <TopFixedHeader />
      <ServicesHomescreen />
      <ServicesDigitalMarketing />
      <ServicesDataAnalyst />
      <ServicesApplicationModernization />
      <ServicesDevelopmentAndMaintenance />
      <ServicesCyberSecurity />
      <ServicesQualityAssuarance />
    </>
  );
}

export default Services;
