import React from "react";
import "../stylesheets/ServicesDevelopmentAndMaintenance.css";

function ServicesDevelopmentAndMaintenance() {
  return (
    <div className="services-development-maintenance-content">
      <h2 className="h2 white-heading h2-1">
        Application Development and Maintenance
      </h2>
      <div
        className="services-development-maintenance-content-left"
        id="services-development-maintenance-content-left"
      >
        <h2 className="h2 white-heading h2-2">
          Application Development and Maintenance
        </h2>
        <p className="p text-black justify-text">
          In this digital age usage of applications and technology by
          organizations has become necessary for ease of operation.
        </p>
        <p className="p text-black justify-text">
          With usage, maintenance and further development become even more
          essential for smooth operations. Businesses now more than ever need to
          build proficient and systematic ways to achieve their strategic
          targets.
        </p>
      </div>
      <div
        className="services-development-maintenance-content-right"
        id="services-development-maintenance-content-right"
      >
        <img src="images/services/services-data-analyst-img.jpg" alt="" />
      </div>
    </div>
  );
}

export default ServicesDevelopmentAndMaintenance;
