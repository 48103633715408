import React from "react";
import "../stylesheet/InnovationProductDevelopment.css";
function InnovationProductDevelopment() {
  return (
    <div className="innovation-product-development">
      <h2 className="h2 white-heading">Product Development</h2>
      <p className="p text-black justify-text">
        The journey of a new product development starts with an idea that should
        be untried or out of the box, forming the foundation for further
        development.
      </p>
      <div className="product-development-content-1-img">
        <img src="images/innovations-product-development-1440x593.png" alt="" />
      </div>
    </div>
  );
}

export default InnovationProductDevelopment;
