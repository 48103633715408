import React from "react";
import TopFixedHeader from "../../../../common/components/TopFixedHeader";
import BlogPageHome from "../../components/BlogPageHome";
import { Helmet } from "react-helmet-async";
import BlogThirtyEightContent from "./components/BlogThirtyEightContent";

function BlogThirtyEight() {
  return (
    <>
      <Helmet>
        <title>
          How Tech Skills Propel Young Professionals Towards Success?
        </title>
        <meta
          name="description"
          content="In this ever-changing career landscape, gaining skill expertise is needed to drive the career workforce."
        />
        <meta
          name="keywords"
          content="demand for tech-savvy professionals,  technological advancement, technology-driven, emerging trends, entrepreneurship ventures, rising challenges"
        />
      </Helmet>
      <TopFixedHeader />
      <BlogPageHome />
      <BlogThirtyEightContent />
    </>
  );
}

export default BlogThirtyEight;

// {
//     "title" : "Social Media: Boost Biz Growth with Engaging Content.",
//     "content":"Digital Marketing, Social Media, Social Media, Content Strategy, Using Hashtags, Audience Engagement,  Target Audience, Social Media Platforms, Social Media Marketing.",
//     "link":"/blogs/social-media-boost-biz-growth-with-engaging-content"
// },
