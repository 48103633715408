import React from "react";
import { /*Button,*/ Drawer } from "antd";
import "../stylesheets/SideDrawer.css";
// import CloseIcon from "@mui/icons-material/Close";
import { Facebook, LinkedIn, Twitter, YouTube } from "@mui/icons-material";
import Links from "../../data/Links.json";

const SideDrawer = (props) => {
  const {LejhroBootcampURL, LejhroTwitter, LejhroFacebook, LejhroInstagram, LejhroYoutube } = Links 
  const onClose = () => {
    props.setDrawerOpen(false);
    // document.body.style.overflowX = 'clip';
  };

  return (
    <>
      <Drawer
        title={
          <a href="/">
            <img
              src="/lejhro_logo_blue.png"
              alt=""
              className="lejhro-logo-primary"
            />
          </a>
        }
        closable
        placement={"right"}
        onClose={onClose}
        open={props.drawerOpen}
        key={"left"}
        className="side-drawer"
        zIndex={4050}
        // getContainer={false}
      >
        {/* <div className="size-drawer-header">
          <a href="/" aria-label="HomePage">
            <img
              src="/lejhro_logo_blue.png"
              alt="Lejhro Blue Logo"
              className="side-drawer-logo"
            />
          </a>
          <Button
            onClick={() => {
              props.setDrawerOpen(false);
            }}
            className="side-drawer-close-button"
            aria-label="Close Button"
          >
            <CloseIcon fontSize="medium" className=""/>
          </Button>
        </div> */}

        <div className="side-drawer-buttons-container">
          <a
            href="/innovations"
            className="side-drawer-button-link"
          >
            Innovations
          </a>
          <a
            href={LejhroBootcampURL}
            className="side-drawer-button-link"
            target="_blank"
            rel="noreferrer"
          >
            Bootcamp
          </a>
          <a
            href="/business-services"
            className="side-drawer-button-link"
          >
            Business Services
          </a>
          <a
            href="/financial-services"
            className="side-drawer-button-link"
          >
            Financial Services
          </a>
          <a href="/about" className="side-drawer-button-link">
            About
          </a>
          <a
            href="/contact-us"
            className="side-drawer-button-link"
          >
            Contact Us
          </a>
          <a
            href="/blogs"
            className="side-drawer-button-link"
          >
            Blogs
          </a>
          <div className="side-drawer-connect">
              <a
                href={LejhroTwitter}
                target="_blank"
                rel="noreferrer"
                aria-label="Twitter"
              >
                <Twitter style={{ fontSize: "30px" }} />
              </a>
              <a
                href={LejhroFacebook}
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <Facebook style={{ fontSize: "30px" }} />
              </a>
              <a
                href={LejhroInstagram}
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram"
              >
                <LinkedIn style={{ fontSize: "30px" }} />
              </a>
              <a
                href={LejhroYoutube}
                target="_blank"
                rel="noreferrer"
                aria-label="YouTube"
              >
                <YouTube style={{ fontSize: "30px" }} />
              </a>
            </div>
        </div>
      </Drawer>
    </>
  );
};

export default SideDrawer;
