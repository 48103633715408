import React from "react";
import BlogFiveContent from "./components/BlogFiveContent";
import TopFixedHeader from './../../../../common/components/TopFixedHeader';
import BlogPageHome from './../../components/BlogPageHome';
import { Helmet } from 'react-helmet-async';

function BlogFive() {
  return (
    <>
      <Helmet>
        <title>Struggling with Anxiety Before Job Interview | Lejhro</title>
        <meta name="description" content="Are you suffering from anxiety before a job interview? Worry not! You need to take some actions to reduce to sail through the interview with confidence." />
        <meta name="keywords" content="Reduce stress, stay calm, Job Interview, Anxiety, Panic, Confidence"/>
      </Helmet>
      <TopFixedHeader/>
      <BlogPageHome/>
      <BlogFiveContent/>
    </>
  );
}

export default BlogFive;
